import { SubChapter, SubChapterType, Tutorial } from '@/components/tutorial/type.ts';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { expand } from 'inline-style-expand-shorthand';
import { ChevronRight } from 'baseui/icon';
import { GettingStartTutorial } from '@/components/tutorial/GettingStartTutorial.tsx';

export interface TutorialSubChapterProps {
  tutorial: Tutorial;
  onSelectSubChapter: (subChapter: SubChapter) => void;
  onClose: () => void;
}

export function TutorialSubChapters({ tutorial, onSelectSubChapter, onClose }: TutorialSubChapterProps) {
  const [css, theme] = useStyletron();
  const subChapters = tutorial.sub_chapter;

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      })}
    >
      {tutorial.id !== '0' && (
        <>
          <p
            className={css({
              fontSize: '12px',
              lineHeight: '16px',
              color: theme.colors['text.secondary'],
            })}
          >
            {tutorial.abstract}
          </p>

          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            })}
          >
            {subChapters.map((subChapter, index) => {
              return subChapter.Type === SubChapterType.Video ? (
                <div
                  onClick={() => {
                    onSelectSubChapter(subChapter);
                  }}
                  key={subChapter.Title}
                  className={css({
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                    fontSize: '12px',
                    fontWeight: 600,
                    justifyContent: 'space-between',
                    ...expand({
                      padding: '8px 16px',
                      borderRadius: '2px',
                      border: `1px solid ${theme.colors['border.tertiary']}`,
                      backgroundColor: theme.colors['background.alternative'],
                    }),
                  })}
                >
                  <div
                    className={css({
                      display: 'flex',
                      gap: '10px',
                    })}
                  >
                    {subChapter.Title}
                  </div>
                  <ChevronRight size={24} color={theme.colors.gray400} />
                </div>
              ) : (
                <div
                  onClick={() => {
                    subChapter?.Url && window.open(subChapter.Url, '_blank');
                  }}
                  key={subChapter.Title}
                  className={css({
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                    fontSize: '12px',
                    fontWeight: 600,
                    justifyContent: 'space-between',
                    ...expand({
                      padding: '8px 16px',
                      borderRadius: '2px',
                      border: `1px solid ${theme.colors['border.tertiary']}`,
                      backgroundColor: theme.colors['background.alternative'],
                    }),
                  })}
                >
                  <div
                    className={css({
                      display: 'flex',
                      gap: '10px',
                    })}
                  >
                    {subChapter.Title}
                  </div>
                  <ChevronRight size={24} color={theme.colors.gray400} />
                </div>
              );
            })}
          </div>
        </>
      )}
      {tutorial.id === '0' && <GettingStartTutorial onClose={onClose} />}
    </div>
  );
}
