// @ts-nocheck
import { tags as t } from '@lezer/highlight';
import { baseTypes } from '@/pages/editor/GSQL/queryKw';
import { CypherKeywords } from '@neo4j-cypher/editor-support';

const operators = [
  ';',
  '(',
  ')',
  '{',
  '}',
  '[',
  ']',
  '$',
  ':',
  '.',
  '=',
  '<',
  '>',
  '+',
  '-',
  '*',
  '`',
  ',',
  '?',
  '|',
  '..',
  '+=',
  '<>',
  '!=',
  '<=',
  '>=',
  '/',
  '%',
  '^',
  '=~',
];

let curPunc;

const keywordRegexes = CypherKeywords.map((w) => new RegExp(`${w}\\b`, 'i'));
const paramTypesRegexes = baseTypes.map((w) => new RegExp(`${w}\\b`, 'i'));
const lineCommentRegex = /\/\/[^\r\n]*/;
const blockCommentRegex = /\/\*([\S\s]*?)\*\//;
const stringRegex = /('([^'\\]|\\.)*'|"([^"\\]|\\.)*")/;
const stringStartRegex = /('([^'\\]|\\.)*|"([^"\\]|\\.)*)/; // match just opened and not closed string as string
const integerRegex = /[+-]?(([1-9][0-9]+)|([0-9]))/;
const decimalRegex = /[+-]?(([1-9][0-9]+)|([0-9]))\.[0-9]+/;
const labelRegex = /[_a-zA-Z][_a-zA-Z0-9]*/;

const tokenBase = (stream) => {
  if (stream.match(lineCommentRegex) || stream.match(blockCommentRegex)) {
    return 'comment';
  } else if (stream.match(stringRegex)) {
    return 'string';
  } else if (stream.match(integerRegex)) {
    return 'number';
  } else if (stream.match(decimalRegex)) {
    return 'number';
  } else if (operators.find((o) => stream.match(o))) {
    if (stream.current() === ':') {
      if (stream.match(labelRegex)) {
        return 'label';
      }
    }

    return 'operator';
  } else if (paramTypesRegexes.find((k) => stream.match(k))) {
    return 'type';
  } else if (keywordRegexes.find((k) => stream.match(k))) {
    return 'keyword';
  } else if (stream.match(stringStartRegex)) {
    return 'string';
  }

  stream.next();
  stream.eatWhile(/[_\p{Letter}\p{Emoji}\d]/u);

  return 'variable';
};
const pushContext = (state, type, col) => {
  state.context = {
    prev: state.context,
    indent: state.indent,
    col,
    type,
  };
  return state.context;
};
const popContext = (state) => {
  state.indent = state.context.indent;
  state.context = state.context.prev;
  return state.context;
};

export const cypher = {
  name: 'Cypher',
  startState() {
    return {
      tokenize: tokenBase,
      context: null,
      indent: 0,
      col: 0,
    };
  },
  token(stream, state) {
    if (stream.sol()) {
      if (state.context && state.context.align == null) {
        state.context.align = false;
      }
      state.indent = stream.indentation();
    }
    if (stream.eatSpace()) {
      return null;
    }
    const style = state.tokenize(stream, state);
    if (style !== 'comment' && state.context && state.context.align == null && state.context.type !== 'pattern') {
      state.context.align = true;
    }
    if (curPunc === '(') {
      pushContext(state, ')', stream.column());
    } else if (curPunc === '[') {
      pushContext(state, ']', stream.column());
    } else if (curPunc === '{') {
      pushContext(state, '}', stream.column());
    } else if (/[\]})]/.test(curPunc)) {
      while (state.context && state.context.type === 'pattern') {
        popContext(state);
      }
      if (state.context && curPunc === state.context.type) {
        popContext(state);
      }
    } else if (curPunc === '.' && state.context && state.context.type === 'pattern') {
      popContext(state);
    } else if (/atom|string|variable/.test(style) && state.context) {
      if (/[}\]]/.test(state.context.type)) {
        pushContext(state, 'pattern', stream.column());
      } else if (state.context.type === 'pattern' && !state.context.align) {
        state.context.align = true;
        state.context.col = stream.column();
      }
    }
    return style;
  },
  indent: (state, textAfter, cx) => {
    const firstChar = textAfter && textAfter.charAt(0);
    let context = state.context;
    if (/[\]}]/.test(firstChar)) {
      while (context && context.type === 'pattern') {
        context = context.prev;
      }
    }
    const closing = context && firstChar === context.type;
    if (!context) return 0;
    if (context.type === 'keywords') return null; // return CodeMirror.commands.newlineAndIndent;
    if (context.align) return context.col + (closing ? 0 : 1);
    return context.indent + (closing ? 0 : cx.unit);
  },
  languageData: {
    commentTokens: { line: '//', block: { open: '/*', close: '*/' } },
  },
  tokenTable: {
    label: t.labelName,
  },
};

export const cypherFuncs = [
  'avg',
  'collect',
  'count',
  'max',
  'min',
  'percentilecont',
  'percentiledisc',
  'stdev',
  'stdevp',
  'sum',
  'keys',
  'labels',
  'nodes',
  'range',
  'relationships',
  'reverse',
  'tail',
  'exp',
  'log',
  'log10',
  'sqrt',
  'abs',
  'ceil',
  'floor',
  'rand',
  'round',
  'sign',
  'acos',
  'asin',
  'atan',
  'atan2',
  'cos',
  'cot',
  'degrees',
  'pi',
  'radians',
  'sin',
  'tan',
  'exists',
  'coalesce',
  'elementId',
  'endnode',
  'head',
  'id',
  'last',
  'length',
  'properties',
  'size',
  'startnode',
  'timestamp',
  'toboolean',
  'tofloat',
  'tointeger',
  'type',
  'left',
  'ltrim',
  'replace',
  'reverse',
  'right',
  'rtrim',
  'size',
  'split',
  'substring',
  'tolower',
  'tostring',
  'toupper',
  'trim',
];
