import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import {
  ExternalGraph,
  ExternalLink,
  ExternalNode,
  GLOBAL_GRAPH_NAME,
  HelperFunctions,
  ValidateResult,
  Vertex,
} from '@tigergraph/tools-models';
import { Color } from '@tigergraph/tools-models/gvis/color';
import Graph from '@tigergraph/tools-ui/esm/graph';
import {
  CytoscapeExtensions,
  GraphEvents,
  GraphRef,
  NodePositions,
  Schema,
  SettingType,
} from '@tigergraph/tools-ui/graph/type';
import { Core } from 'cytoscape';
import {
  forwardRef,
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { EdgeEditor } from '@/components/schemaDesigner/EdgeEditor';
import { VertexEditor } from '@/components/schemaDesigner/VertexEditor';
import { useSchemaDesignerContext } from '@/contexts/schemaDesignerContext';
import { convertSchemaToGraph } from '@tigergraph/tools-ui/graph/data';
import { MdSave } from 'react-icons/md';
import { initStyle, randomString } from '@/utils/schemaDesigner';
import { Button } from '@tigergraph/app-ui-lib/button';
import {
  DBEdgeStyleJson,
  DBGraphStyleJson,
  DBVertexStyleJson,
  Edge,
  GSQLEdgeJson,
  GSQLVertexJson,
  SchemaChange,
  TypeCheckedStatus,
} from '@tigergraph/tools-models/topology';
import { LayoutType } from '@tigergraph/tools-ui/graph/layouts';
import { getUserUploadedIconPathPrefix } from '@tigergraph/tools-ui/insights/utils/path';
import '@tigergraph/tools-ui/index.css';
import { Layer } from 'baseui/layer';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';
import isEqual from 'lodash-es/isEqual';
import { FiPlus } from 'react-icons/fi';
import { GRAPH_ICON } from '@tigergraph/tools-ui/graph/popper/styledGraphIcon';
import DeleteTypeModal from '@/pages/editor/DeleteTypeModal';
import omit from 'lodash-es/omit';
import GlobalTypePopover from '@/pages/editor/GlobalTypePopover';
import { EmptySchema } from '@/components/schemaDesigner/EmptySchema';
import useSize from 'ahooks/lib/useSize';
import { StyleObject } from 'styletron-standard';
import ConfirmModal from '@/components/ConfirmModal';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { GSQLEditorClassName } from '@/pages/editor/file/CodeEditor';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import { MdOutlineUndo, MdOutlineRedo, MdDeleteOutline } from 'react-icons/md';
import { expand } from 'inline-style-expand-shorthand';
import { useTheme } from '@/contexts/themeContext';
import { cloneDeep } from 'lodash-es';
import GraphSelect from '@/components/graphSelect';
import { SIZE } from 'baseui/select';
import ErrorMessage from '@/components/ErrorMessage';
import { useSchemaChangeMutation } from '@/components/schemaDesigner/hooks/useSchemaChangeMutation';

export interface GraphResultProps {
  id: string;
  graph?: ExternalGraph;
  layout?: LayoutType;
  schema: Schema;
  graphName: string;
  isSchemaGraph?: boolean;
  graphEvents?: GraphEvents;
  enableEditor?: boolean;
  enableCreate?: boolean;
  enableDelete?: boolean;
  enableSave?: boolean;
  enableUndoRedo?: boolean;
  enableShortcuts?: boolean;
  hideSearch?: boolean;
  handleUndo?: () => void;
  handleRedo?: () => void;
  handleCreateVertex?: () => void;
  confirmDeleteVerticesOrEdges?: (items: (ExternalNode | ExternalLink)[]) => void;
  refetchSchema?: () => void;
  showCreateEdgeTooltip?: boolean;
  enableGraphSelect?: boolean;
  schemaStyle?: DBGraphStyleJson;
  globalSchemaStyle?: DBGraphStyleJson;
}

export interface GraphResultRef {
  vertex: Vertex | undefined;
  edge: Edge | undefined;
  schema: Schema;
  graph: ExternalGraph | undefined;
  isAddingVertex: boolean;
  isSchemaChanged: () => boolean;
  handleCreateVertex: (position?: { x: number; y: number }) => Vertex;
  handleCreateEdge: (source: ExternalNode, target: ExternalNode | ExternalLink) => Edge;
  handleCreateNodeAndEdge: (source: ExternalNode, position: { x: number; y: number }) => [Vertex, Edge] | undefined;
  handleUpdateVertex: (updateVertex: Vertex, vertexTypeName: string) => ValidateResult;
  handleUpdateEdge: (edge: Edge, edgeTypeName: string) => ValidateResult;
  handleSaveSchema: (warning?: boolean) => Promise<void>;
  confirmDeleteVerticesOrEdges: (items: (ExternalNode | ExternalLink)[]) => void;
  handleChangePosition: () => void;
  handleDeleteVertexIcon: (icon: string) => void;
  renderChart: () => void;
  centerGraph: () => void;
  schemaStyleChanged: () => boolean;
  graphRef: MutableRefObject<GraphRef | null>;
  cyRef: MutableRefObject<(Core & CytoscapeExtensions) | null>;
}

const GraphSizeLimit = 5000;
const GlobalIconNumberLimit = 200;

const primaryIdName = 'id';
const primaryIdTypeDefaultValue = 'STRING';

const GraphResult = forwardRef<GraphResultRef, GraphResultProps>((props, ref) => {
  const {
    graphName,
    id,
    isSchemaGraph,
    schema,
    graph,
    layout,
    enableEditor,
    enableCreate,
    enableDelete,
    enableSave,
    enableUndoRedo,
    enableShortcuts,
    hideSearch,
    schemaStyle,
    globalSchemaStyle,
    showCreateEdgeTooltip = false,
    enableGraphSelect,
  } = props;
  const { currentWorkspace, graphNames } = useWorkspaceContext();
  const [css, theme] = useStyletron();
  const { themeType } = useTheme();
  const [isAddingVertex, setIsAddingVertex] = useState(false);
  const [schemaCopy, setSchemaCopy] = useState<Schema>(schema);
  const [graphCopy, setGraphCopy] = useState<ExternalGraph | undefined>(graph);
  const [vertexPopoverVisible, setVertexPopoverVisible] = useState(false);
  const [edgePopoverVisible, setEdgePopoverVisible] = useState(false);
  const [vertex, setVertex] = useState<Vertex | undefined>(undefined);
  const [edge, setEdge] = useState<Edge | undefined>(undefined);
  const [selectedVertexName, setSelectedVertexName] = useState<string>('');
  const [selectedEdgeName, setSelectedEdgeName] = useState<string>('');
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [errorMsg, setErrorMsg] = useState('');
  const [initialStyle, setInitialStyle] = useState<DBGraphStyleJson | undefined>(
    schemaStyle ?? {
      vertexStyles: {},
      edgeStyles: {},
    }
  );
  const [isOpenDeleteTypeModal, setIsOpenDeleteTypeModal] = useState(false);
  const [deleteItems, setDeleteItems] = useState<(ExternalNode | ExternalLink)[]>([]);
  const [unsavedWarning, setUnsavedWarning] = useState<string[] | undefined>();
  const [deleteWarning, setDeleteWarning] = useState<string[] | undefined>();
  const [saveWarning, setSaveWarning] = useState<string[] | undefined>();
  const [isLoadingSchemaChangeJobs, setIsLoadingSchemaChangeJobs] = useState(false);
  const [schemaChangeJobs, setSchemaChangeJobs] = useState<SchemaChange[]>([]);
  const globalUpdateIndicesRef = useRef<number[]>([]);
  const divRef = useRef<HTMLDivElement | undefined>(undefined);
  const graphNameRef = useRef<string>('');
  const initialLayoutRef = useRef(false);
  const editorSize = useSize(divRef);
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const buttonGroupSize = useSize(buttonContainerRef);

  const isGlobalView = useMemo(() => {
    return graphName === GLOBAL_GRAPH_NAME;
  }, [graphName]);

  const color = useMemo(() => {
    return new Color();
  }, []);

  const cyRef = useRef<(Core & CytoscapeExtensions) | null>(null);
  const graphRef = useRef<GraphRef | null>(null);

  const { changeService, designerService } = useSchemaDesignerContext();
  const history = designerService?.getHistory();

  useEffect(() => {
    if (graphNameRef.current !== graphName) {
      centerGraph();
      graphNameRef.current = graphName;
    }
  }, [graphName]);

  useEffect(() => {
    if (!schemaCopy) {
      return;
    }
    setTimeout(() => {
      graphRef.current?.setDrawMode(!!enableCreate);
    }, 500);
  }, [enableCreate, isSchemaGraph, schemaCopy]);

  const { saveSchemaAsync, saveSchemaLoading, saveSchemaStyleAsync } = useSchemaChangeMutation({
    graphName,
    workspace: currentWorkspace,
    onSchemaStyleChangeSuccess: (data) => {
      setInitialStyle(data);
    },
  });

  const schemaStyleChanged = useCallback(() => {
    const dbGraphStyle = designerService.getDBGraphStyleJson();
    const upsertedSchemaStyle = JSON.parse(JSON.stringify(dbGraphStyle));
    return upsertedSchemaStyle && !isEqual(initialStyle, upsertedSchemaStyle);
  }, [designerService, initialStyle]);

  const updateSchemaChangeJobs = useCallback(() => {
    const timer = setTimeout(() => {
      setIsLoadingSchemaChangeJobs(true);
    }, 1000);
    setTimeout(async () => {
      try {
        const data = await new Promise<SchemaChange[]>((resolve) => {
          const jobs = changeService?.getSchemaChangeJobs(history, isGlobalView);
          resolve(jobs);
        });
        setSchemaChangeJobs(data);
      } catch (error) {
        console.error(error);
      } finally {
        clearTimeout(timer);
        setIsLoadingSchemaChangeJobs(false);
      }
    });
  }, [changeService, history, isGlobalView]);

  const isSchemaChanged = useCallback(() => {
    return schemaChangeJobs.length !== 0 || schemaStyleChanged();
  }, [schemaChangeJobs.length, schemaStyleChanged]);

  const disableButton = saveSchemaLoading;
  const disableSaveButton = disableButton || !isSchemaChanged();
  const [settings, setSettings] = useState<SettingType>({
    layout: layout || 'Preset',
  });

  const generateWarningForDropChanges = useCallback(() => {
    if (!enableCreate || schemaChangeJobs.length === 0) {
      return [];
    }
    const warnings = [];

    const flatten = flattenSchemaChangeJobs(schemaChangeJobs);

    let hasDrop = flatten.dropVertexTypes.length + flatten.dropEdgeTypes.length;
    if (flatten.graphs) {
      hasDrop += flatten.graphs[0].dropVertexTypes.length + flatten.graphs[0].dropEdgeTypes.length;
    }

    let hasAttributeDrop =
      flatten.alterVertexTypes.reduce((prev, type) => prev + type.dropAttributes.length, 0) +
      flatten.alterEdgeTypes.reduce((prev, type) => prev + type.dropAttributes.length, 0);

    if (hasDrop || hasAttributeDrop) {
      warnings.push('Saving the changes will erase data for:');
      const vertexChange = [];
      if (flatten.dropVertexTypes.length > 0) {
        vertexChange.push(...flatten.dropVertexTypes);
      }
      if (flatten.graphs && flatten.graphs[0].dropVertexTypes.length > 0) {
        vertexChange.push(...flatten.graphs[0].dropVertexTypes);
      }
      if (vertexChange.length > 0) {
        warnings.push(
          `- Vertex type${vertexChange.length > 1 ? 's' : ''} "${HelperFunctions.joinStrAsPara(vertexChange)}";`
        );
      }

      if (flatten.alterVertexTypes.length > 0) {
        flatten.alterVertexTypes.forEach((type) => {
          if (type.dropAttributes.length > 0) {
            warnings.push(
              `- Attribute${type.dropAttributes.length > 1 ? 's' : ''} "${HelperFunctions.joinStrAsPara(
                type.dropAttributes
              )}" of Vertex "${type.name}";`
            );
          }
        });
      }

      const edgeChange = [];
      if (flatten.dropEdgeTypes.length > 0) {
        edgeChange.push(...flatten.dropEdgeTypes);
      }
      if (flatten.graphs && flatten.graphs[0].dropEdgeTypes.length > 0) {
        edgeChange.push(...flatten.graphs[0].dropEdgeTypes);
      }
      if (edgeChange.length > 0) {
        warnings.push(`- Edge type${edgeChange.length > 1 ? 's' : ''} "${HelperFunctions.joinStrAsPara(edgeChange)}";`);
      }
      if (flatten.alterEdgeTypes.length > 0) {
        flatten.alterEdgeTypes.forEach((type) => {
          if (type.dropAttributes.length > 0) {
            warnings.push(
              `- Attribute${type.dropAttributes.length > 1 ? 's' : ''} "${HelperFunctions.joinStrAsPara(
                type.dropAttributes
              )}" of Edge "${type.name}";`
            );
          }
        });
      }
      warnings.push('You can reload data to affected vertex and edge types after saving the changes.');
    }
    return warnings;
  }, [enableCreate, schemaChangeJobs]);

  const setWarningForUnsavedChanges = useCallback(() => {
    if (!enableCreate) {
      return;
    }
    if (schemaChangeJobs.length > 0) {
      const warnings = ['You have not saved your changes to the schema.'];
      const dropWarnings = generateWarningForDropChanges();
      warnings.push(...dropWarnings);
      setUnsavedWarning(warnings);
    } else if (schemaStyleChanged()) {
      setUnsavedWarning(['You have not saved your changes to the schema style.']);
    } else {
      setUnsavedWarning(undefined);
    }
  }, [enableCreate, generateWarningForDropChanges, schemaChangeJobs.length, schemaStyleChanged]);

  useEffect(() => {
    setWarningForUnsavedChanges();
  }, [setWarningForUnsavedChanges]);

  const updateGlobalTypesIcon = useCallback(() => {
    setTimeout(() => {
      const globalTypes = designerService.getAllGlobalTypes();
      if (isSchemaGraph && !isGlobalView && globalTypes.length <= GlobalIconNumberLimit) {
        const vertices = graphRef.current?.getNodesByTypes(globalTypes);
        vertices?.forEach((item) => graphRef.current?.displayNodeIcon(item, GRAPH_ICON.global));
        const edges = graphRef.current?.getLinksByTypes(globalTypes);
        edges?.forEach((item) => graphRef.current?.displayLinkIcon(item, GRAPH_ICON.global));
      }
    }, 100);
  }, [designerService, isGlobalView, isSchemaGraph]);

  const renderChart = useCallback(() => {
    const { graph } = designerService;
    initStyle(graph, color);
    const curSchema = graph.dumpToGSQLJson() as Schema;
    if (!curSchema) {
      return;
    }
    curSchema.VertexTypes.forEach((vertex) => {
      vertex['style'] = graph.getVertex(vertex.Name)?.style;
    });
    curSchema.EdgeTypes.forEach((edge) => {
      edge['style'] = graph.getEdge(edge.Name)?.style;
    });
    setSchemaCopy(curSchema);
    if (isSchemaGraph) {
      setGraphCopy(convertSchemaToGraph(curSchema as Schema));
    }
    updateGlobalTypesIcon();
    updateSchemaChangeJobs();
  }, [color, designerService, isSchemaGraph, updateGlobalTypesIcon, updateSchemaChangeJobs]);

  const generateDeleteWarningMsg = useCallback((type: Vertex | Edge) => {
    return `${'primaryId' in type ? 'Vertex' : 'Edge'} type ${type.name} is used on graph ${type.usage.join(', ')}.`;
  }, []);

  const handleChangePosition = useCallback(() => {
    let vertexPositions: [string, number, number][] = [];
    if (graphRef.current) {
      const positions = graphRef.current?.graphPositions();
      vertexPositions = vertexPositions.concat(
        positions.map((p) => {
          const type = p[0].split('#').shift() as string;
          return [type, p[1], p[2]];
        })
      );
      designerService.updateVerticesPositions(vertexPositions);
    }
  }, [designerService]);

  const flattenSchemaChangeJobs = (schemaChangeJobs: SchemaChange[]): SchemaChange => {
    return cloneDeep(schemaChangeJobs).reduce((result, job) => {
      const resultKeyList = Object.keys(result);
      const jobKeyList = Object.keys(job);
      const keyList = resultKeyList.length >= jobKeyList.length ? resultKeyList : jobKeyList;
      // @ts-ignore
      keyList.forEach((key) => (result[key] = (result[key] || []).concat(job[key] || [])));
      return result;
    });
  };

  useEffect(() => {
    try {
      let curSchema = cloneDeep(schema);
      let currentSchemaStyle = cloneDeep(
        schemaStyle ?? {
          vertexStyles: {},
          edgeStyles: {},
        }
      );
      if (
        (!currentSchemaStyle || Object.keys(currentSchemaStyle?.vertexStyles).length === 0) &&
        curSchema.VertexTypes.length > 0
      ) {
        const globalTypes = designerService.getAllGlobalTypes();
        const color = new Color();
        currentSchemaStyle = {
          vertexStyles: curSchema.VertexTypes.reduce(
            (prev, v, i) => {
              if (globalTypes.includes(v.Name) && globalSchemaStyle?.vertexStyles[v.Name]) {
                prev[v.Name] = globalSchemaStyle?.vertexStyles[v.Name];
              } else {
                prev[v.Name] = {
                  fillColor: color.getColor(v.Name),
                  x: 0,
                  y: 0,
                  other: {},
                };
              }
              return prev;
            },
            {} as {
              [vertexType: string]: DBVertexStyleJson;
            }
          ),
          edgeStyles: curSchema.EdgeTypes.reduce(
            (prev, e) => {
              if (globalTypes.includes(e.Name) && globalSchemaStyle?.edgeStyles[e.Name]) {
                prev[e.Name] = globalSchemaStyle?.edgeStyles[e.Name];
              } else {
                prev[e.Name] = {
                  fillColor: color.getColor(e.Name),
                  other: {},
                };
              }
              return prev;
            },
            {} as {
              [edgeType: string]: DBEdgeStyleJson;
            }
          ),
        };
        initialLayoutRef.current = false;
      }
      designerService.setSchemaAndStyle(curSchema, currentSchemaStyle);
      setErrorMsg('');
    } catch (e: any) {
      setErrorMsg(e?.message ?? 'Can not render schema');
      console.error(e?.message ?? 'Can not render schema');
    }
  }, [designerService, globalSchemaStyle, schema, schemaStyle]);

  useEffect(() => {
    renderChart();
  }, [graphName, renderChart]);

  useEffect(() => {
    if (
      initialLayoutRef.current === false &&
      graphName === designerService.graph.name &&
      (!initialStyle ||
        Object.keys(initialStyle.vertexStyles).length === 0 ||
        Object.values(initialStyle.vertexStyles).every((style) => style.x === 0 && style.y === 0)) &&
      schema.VertexTypes.length > 0
    ) {
      initialLayoutRef.current = true;
      setTimeout(() => {
        graphRef.current?.runLayout(
          {
            name: 'fcose',
            animation: false,
            fit: false,
          },
          () => {
            centerGraph();
            handleChangePosition();
            if (enableSave && !errorMsg) {
              saveSchemaStyleAsync(designerService.getDBGraphStyleJson());
            }
            setInitialStyle(designerService.getDBGraphStyleJson());
            designerService.reset();
          }
        );
      }, 500);
    }
  }, [
    graphName,
    designerService,
    enableSave,
    errorMsg,
    handleChangePosition,
    initialStyle,
    saveSchemaStyleAsync,
    schema.VertexTypes.length,
  ]);

  const initializeEdge = useCallback((): Edge => {
    const edge = new Edge();
    edge.name = `edge_type_${randomString(2)}`;
    edge.style = designerService.getNewEdgeStyle(randomString(32), color);
    if (isGlobalView) {
      edge.isLocal = false;
      edge.usage = [];
    }
    return edge;
  }, [color, designerService, isGlobalView]);

  const handleDelete = useCallback(() => {
    // Remove the selected vertices and edge from the graph.

    let vertexTypes: string[] = [];
    let edgeTypes: { from: string; to: string; type: string }[] = [];
    const selectedElements = graphRef.current?.selectedElements();
    selectedElements?.nodes.forEach((node) => vertexTypes.push(node.type));
    selectedElements?.links.forEach((link) =>
      edgeTypes.push({
        from: link.source.type,
        to: link.target.type,
        type: link.type,
      })
    );

    // If in a graph, global types cannot be deleted.
    if (!isGlobalView) {
      const globalTypes = designerService.getAllGlobalTypes();
      vertexTypes = vertexTypes.filter((vertex) => !globalTypes.includes(vertex));
      edgeTypes = edgeTypes.filter((edge) => !globalTypes.includes(edge.type));
    } else {
      // Block deleting global vertex or edge types that is used in certain graph(s).
      const vertexTypesDisableRemove: Vertex[] = [];
      const edgeTypesDisableRemove: Edge[] = [];
      vertexTypes = vertexTypes.filter((name) => {
        const vertex = designerService.getVertex(name);
        if (vertex && vertex.usage.length > 0) {
          vertexTypesDisableRemove.push(vertex);
          return;
        } else {
          return name;
        }
      });
      edgeTypes = edgeTypes.filter((edgeType) => {
        const edge = designerService.getEdge(edgeType.type);
        if (edge && edge.usage.length > 0) {
          edgeTypesDisableRemove.push(edge);
        } else {
          return edgeType;
        }
      });

      if (vertexTypesDisableRemove.length > 0 || edgeTypesDisableRemove.length > 0) {
        const messages: string[] = [];
        vertexTypesDisableRemove.forEach((type) => {
          messages.push('- ' + generateDeleteWarningMsg(type));
        });
        edgeTypesDisableRemove.forEach((type) => {
          messages.push('- ' + generateDeleteWarningMsg(type));
        });
        messages.push('Please drop from the graph(s) first.');
        setDeleteWarning(messages);
      }
    }

    // Only do removal if there is something to delete.
    if (vertexTypes.length > 0 || edgeTypes.length > 0) {
      // Add forward edge type if any selected edge type is reverse edge type.
      edgeTypes.forEach((edgeType) => {
        const reverseEdgeType = designerService.getReverseEdgeType(edgeType.type);
        if (reverseEdgeType) {
          edgeTypes.push({
            from: edgeType.to,
            to: edgeType.from,
            type: reverseEdgeType,
          });
        }
      });
      designerService.remove(vertexTypes, edgeTypes);
      // Reset selected items
      graphRef?.current?.selectNodesByTypes([]);
      graphRef?.current?.selectEdgesByTypes([]);
      setSelectedCount(0);
      renderChart();
    }
  }, [designerService, generateDeleteWarningMsg, isGlobalView, renderChart]);

  const updateGlobalTypes = useCallback(
    (globalVertexTypes: TypeCheckedStatus[], globalEdgeTypes: TypeCheckedStatus[]) => {
      const currentTypes = designerService.getAllVertexTypes().concat(designerService.getAllEdgeTypes());
      const verticesToRemove = Object.values(globalVertexTypes)
        .filter((vertex) => !vertex.disabled && !vertex.selected)
        .map((vertex) => vertex.vertexOrEdgeType.Name)
        .filter((vertex) => currentTypes.includes(vertex));
      const edgesToRemove = Object.values(globalEdgeTypes)
        .filter((edge) => !edge.disabled && !edge.selected)
        .map((edge) => edge.vertexOrEdgeType.Name)
        .filter((edge) => currentTypes.includes(edge));
      const verticesToAdd: Vertex[] = [];
      const edgesToAdd: Edge[] = [];
      globalVertexTypes.forEach((type) => {
        if (type.selected && !currentTypes.includes(type.vertexOrEdgeType.Name)) {
          const vertex = new Vertex();
          const typeJson = omit(type.vertexOrEdgeType, 'Usage');
          vertex.loadFromGSQLJson(typeJson as GSQLVertexJson);
          verticesToAdd.push(vertex);
        }
      });
      globalEdgeTypes.forEach((type) => {
        if (type.selected && !currentTypes.includes(type.vertexOrEdgeType.Name)) {
          const edge = new Edge();
          const typeJson = omit(type.vertexOrEdgeType, 'Usage');
          edge.loadFromGSQLJson(typeJson as GSQLEdgeJson);
          edgesToAdd.push(edge);
        }
      });
      // TODO: Due to current schema change mapping logic,
      // If user assign and drop types in one single operation, split the assign and drop into two history.
      // Need to think about this in the future.
      const split =
        (verticesToRemove.length > 0 || edgesToRemove.length > 0) &&
        (verticesToAdd.length > 0 || edgesToAdd.length > 0);
      const res = split
        ? designerService.updateGlobalTypes(verticesToRemove, edgesToRemove, [], []) &&
          designerService.updateGlobalTypes([], [], verticesToAdd, edgesToAdd)
        : designerService.updateGlobalTypes(verticesToRemove, edgesToRemove, verticesToAdd, edgesToAdd);

      if (res.success) {
        if (split) {
          const curHistoryIndex = designerService.curHistoryPointer;
          globalUpdateIndicesRef.current.push(curHistoryIndex - 1, curHistoryIndex);
        }

        verticesToAdd.forEach((vertex) => {
          const vertexStyle = globalSchemaStyle?.vertexStyles[vertex.name];
          if (vertexStyle) {
            designerService.updateVertexStyleFromJson(vertex.name, vertexStyle);
          } else {
            designerService.updateVertexStyle(vertex.name, designerService.getNewVertexStyle(vertex.name, color));
          }
        });
        edgesToAdd.forEach((edge) => {
          const edgeStyle = globalSchemaStyle?.edgeStyles[edge.name];
          const edgeColor = edgeStyle
            ? edgeStyle.fillColor
            : designerService.getNewEdgeStyle(edge.name, color).fillColor;
          designerService.updateEdgeStrokeColor(edge.name, edgeColor);
        });
        graphRef?.current?.removeAllIcons();
      } else {
        setErrorMsg(res.message || 'Error');
      }
    },
    [color, designerService, globalSchemaStyle]
  );

  const deleteSelectedVerticesOrEdges = useCallback(() => {
    const nodes = graphRef.current?.selectedNodes() || [];
    const edges = graphRef.current?.selectedEdges() || [];
    if (nodes?.length > 0 || edges!.length > 0) {
      setDeleteItems([...nodes, ...edges]);
      setIsOpenDeleteTypeModal(true);
      setVertex(undefined);
      setEdge(undefined);
      setVertexPopoverVisible(false);
      setEdgePopoverVisible(false);
    }
  }, []);

  const deleteGlobalVerticesOrEdges = useCallback(
    (items: (ExternalNode | ExternalLink)[]) => {
      const globalTypesInGraph = designerService.getAllGlobalTypes();

      const graphGSQL = designerService.getGSQLGraphJson();

      const globalVertexTypes = graphGSQL.VertexTypes.filter((vertex) => !vertex.IsLocal).map((vertex) => ({
        vertexOrEdgeType: vertex,
        selected: globalTypesInGraph.includes(vertex.Name),
        disabled: false,
      }));
      const globalEdgeTypes = graphGSQL.EdgeTypes.filter((edge) => !edge.IsLocal).map((edge) => ({
        vertexOrEdgeType: edge,
        selected: globalTypesInGraph.includes(edge.Name),
        disabled: false,
      }));
      for (const item of items) {
        if ('id' in item) {
          const target = globalVertexTypes.filter((vertexType) => vertexType.vertexOrEdgeType.Name === item.type);
          if (!target) {
            continue;
          }
          target[0].selected = false;
          HelperFunctions.toggleCheckStatus(target, globalEdgeTypes, true);
        } else if ('source' in item) {
          const target = globalEdgeTypes.filter((edgeType) => edgeType.vertexOrEdgeType.Name === item.type);
          if (!target) {
            continue;
          }
          target[0].selected = false;
          HelperFunctions.toggleCheckStatus(target, globalVertexTypes);
        }
      }
      updateGlobalTypes(globalVertexTypes, globalEdgeTypes);
      const curHistoryIndex = designerService.curHistoryPointer;
      globalUpdateIndicesRef.current.push(curHistoryIndex);
    },
    [designerService, updateGlobalTypes]
  );

  const confirmDeleteVerticesOrEdges = useCallback(
    (items: (ExternalNode | ExternalLink)[]) => {
      if (!isGlobalView) {
        const globalTypesInGraph = designerService.getAllGlobalTypes();
        const removeItems = items.filter((item) => globalTypesInGraph.includes(item.type));
        if (removeItems.length > 0) {
          deleteGlobalVerticesOrEdges(removeItems);
        }
      }
      handleDelete();
      props.confirmDeleteVerticesOrEdges?.(items);
    },
    [deleteGlobalVerticesOrEdges, designerService, handleDelete, isGlobalView, props]
  );

  const handleSave = useCallback(
    async (warning?: boolean) => {
      const semanticCheck = designerService?.semanticCheckGraph();
      if (semanticCheck?.success) {
        if (warning) {
          const saveWarning = generateWarningForDropChanges();
          if (saveWarning.length > 0) {
            setSaveWarning(saveWarning);
            return;
          }
        }
        const graphStyle = designerService?.getDBGraphStyleJson();
        if (schemaChangeJobs.length > 0) {
          await saveSchemaAsync(schemaChangeJobs);
        }
        if (schemaStyleChanged()) {
          await saveSchemaStyleAsync(graphStyle);
        }
        designerService.reset();
        renderChart();
      } else {
        console.log('semanticCheck error:', semanticCheck?.message);
      }
    },
    [
      designerService,
      generateWarningForDropChanges,
      renderChart,
      saveSchemaAsync,
      saveSchemaStyleAsync,
      schemaChangeJobs,
      schemaStyleChanged,
    ]
  );

  const handleUpdateVertexOrEdge = useCallback(() => {
    if (vertex && designerService.getVertex(vertex.name)) {
      setVertex(designerService.getVertex(vertex.name)?.clone());
    } else {
      setVertex(undefined);
      setVertexPopoverVisible(false);
    }
    if (edge && designerService.getEdge(edge.name)) {
      setEdge(designerService.getEdge(edge.name)?.clone());
    } else {
      setEdge(undefined);
      setEdgePopoverVisible(false);
    }
  }, [designerService, edge, vertex]);

  const centerGraph = () => {
    setTimeout(() => {
      graphRef?.current?.centerGraph();
    }, 200);
  };

  const handleUndo = useCallback(() => {
    designerService.undo();
    if (globalUpdateIndicesRef.current.includes(designerService.curHistoryPointer)) {
      designerService.undo();
    }
    renderChart();
    handleUpdateVertexOrEdge();
    const selectedElements = graphRef.current?.selectedElements();
    setSelectedCount((selectedElements?.nodes.length || 0) + (selectedElements?.links.length || 0));
    props.handleUndo?.();
  }, [designerService, handleUpdateVertexOrEdge, props, renderChart]);

  const handleRedo = useCallback(() => {
    designerService.redo();
    if (globalUpdateIndicesRef.current.includes(designerService.curHistoryPointer)) {
      designerService.redo();
    }
    renderChart();
    handleUpdateVertexOrEdge();
    const selectedElements = graphRef.current?.selectedElements();
    setSelectedCount((selectedElements?.nodes.length || 0) + (selectedElements?.links.length || 0));
    props.handleRedo?.();
  }, [designerService, handleUpdateVertexOrEdge, props, renderChart]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!enableShortcuts) {
        return;
      }
      if (event.repeat) {
        event.preventDefault();
        return;
      }

      let focusInEditor = document.querySelector(`.${GSQLEditorClassName}`)?.contains(document.activeElement);
      if (focusInEditor) {
        return;
      }

      if ((event.key.toLowerCase() === 'backspace' || event.key.toLowerCase() === 'delete') && enableDelete) {
        // Delete selected vertices and edges when pressing delete key.
        deleteSelectedVerticesOrEdges();
      } else if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 's' && enableSave) {
        handleSave(true);
        event.preventDefault();
      } else if (event.key.toLowerCase() === 'v' && enableCreate) {
        setIsAddingVertex(true);
      } else if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key.toLowerCase() === 'z') {
        handleRedo();
      } else if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'z') {
        handleUndo();
      } else if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key.toLowerCase() === 'r') {
        centerGraph();
        event.preventDefault();
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      setIsAddingVertex(false);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [
    deleteSelectedVerticesOrEdges,
    enableCreate,
    enableDelete,
    enableSave,
    enableShortcuts,
    handleRedo,
    handleSave,
    handleUndo,
  ]);

  const handleVertexPopoverVisible = useCallback(
    (visible: boolean) => {
      if (enableEditor) {
        setVertexPopoverVisible(visible);
      }
    },
    [enableEditor]
  );

  const handleEdgePopoverVisible = useCallback(
    (visible: boolean) => {
      if (enableEditor) {
        setEdgePopoverVisible(visible);
      }
    },
    [enableEditor]
  );

  const initializeVertex = useCallback(
    (position?: { x: number; y: number }): Vertex => {
      const vertex = new Vertex();
      vertex!.name = `vertex_type_${randomString(4)}`;
      vertex.primaryId.type.name = primaryIdTypeDefaultValue;
      vertex.primaryId.name = primaryIdName;
      vertex.primaryId.primaryIdAsAttribute = true;
      vertex.config['PRIMARY_ID_AS_ATTRIBUTE'] = true;
      vertex.style = designerService.getNewVertexStyle(randomString(32), color);

      if (position) {
        vertex.style.x = position.x;
        vertex.style.y = position.y;
      }
      if (isGlobalView) {
        vertex.isLocal = false;
        vertex.usage = [];
      }
      return vertex;
    },
    [color, designerService, isGlobalView]
  );

  const handleCreateVertex = useCallback(
    (position?: { x: number; y: number }): Vertex => {
      const cy = cyRef.current;
      const container = cy?.container();
      let viewportCenterX = 0;
      let viewportCenterY = 0;

      if (cy && container) {
        const zoom = cy.zoom();
        const pan = cy.pan();

        const viewportWidth = container.clientWidth;
        const viewportHeight = container.clientHeight;
        viewportCenterX = (viewportWidth / 2 - pan.x) / zoom;
        viewportCenterY = (viewportHeight / 2 - pan.y) / zoom;
      }
      const newVertex = initializeVertex(position ? position : { x: viewportCenterX, y: viewportCenterY });
      const vertexName = newVertex!.name;
      setVertex(newVertex.clone());
      setEdge(undefined);
      setSelectedVertexName(newVertex.name);
      designerService?.addVertex(newVertex.clone());
      renderChart();

      setTimeout(() => {
        // Add the first vertex
        if (designerService.getAllVertexTypes().length === 1) {
          graphRef.current?.centerGraph();
        }
        handleVertexPopoverVisible(true);
        handleEdgePopoverVisible(false);
        graphRef?.current?.unselectElements();
        graphRef?.current?.selectNodesByTypes([vertexName]);
      }, 500);
      return newVertex.clone();
    },
    [designerService, handleEdgePopoverVisible, handleVertexPopoverVisible, initializeVertex, renderChart]
  );

  const handleCreateEdge = useCallback(
    (source: ExternalNode, target: ExternalNode | ExternalLink): Edge => {
      const newEdge = initializeEdge();
      newEdge.fromToVertexTypePairs = [{ from: source.type, to: target.type }];
      designerService.addEdge(newEdge.clone());
      setEdge(newEdge.clone());
      setSelectedEdgeName(newEdge.name);
      setVertex(undefined);
      renderChart();
      setTimeout(() => {
        graphRef?.current?.selectEdges([
          {
            type: newEdge.name,
            source,
            target: target as ExternalNode,
          },
        ]);
      }, 1000);
      return newEdge.clone();
    },
    [designerService, initializeEdge, renderChart]
  );

  const handleCreateNodeAndEdge = useCallback(
    (source: ExternalNode, position: { x: number; y: number }): [Vertex, Edge] | undefined => {
      if (!position) {
        return;
      }
      const newVertex = initializeVertex(position);
      const vertexName = newVertex!.name;
      const newEdge = initializeEdge();
      newEdge.fromToVertexTypePairs = [{ from: source.type, to: vertexName || '' }];
      designerService.addVertex(newVertex.clone());
      designerService.addEdge(newEdge.clone());
      setVertex(newVertex.clone());
      setEdge(undefined);
      setSelectedVertexName(vertexName);
      renderChart();
      setTimeout(() => {
        handleVertexPopoverVisible(true);
        handleEdgePopoverVisible(false);
        graphRef?.current?.unselectElements();
        graphRef?.current?.selectNodesByTypes([vertexName]);
      }, 500);
      return [newVertex.clone(), newEdge.clone()];
    },
    [
      designerService,
      handleEdgePopoverVisible,
      handleVertexPopoverVisible,
      initializeEdge,
      initializeVertex,
      renderChart,
    ]
  );

  const presetNodePositions: NodePositions = useMemo((): NodePositions => {
    const graph = designerService?.graph;
    const curSchema = graph.dumpToGSQLJson();
    const positions: NodePositions = {};
    curSchema.VertexTypes.forEach((v) => {
      const style = graph?.getVertex(v.Name)?.style;
      positions[`${v.Name}#${v.Name}`] = {
        x: style?.x,
        y: style?.y,
      };
    });
    return positions;
  }, [designerService?.graph]);

  const handleUpdateVertex = useCallback(
    (updateVertex: Vertex, vertexTypeName?: string): ValidateResult => {
      updateVertex.style.x =
        designerService.getVertex(vertexTypeName ?? updateVertex.name)?.style.x ?? updateVertex.style.x;
      updateVertex.style.y =
        designerService.getVertex(vertexTypeName ?? updateVertex.name)?.style.y ?? updateVertex.style.y;
      const res = designerService.updateVertex(vertexTypeName ?? selectedVertexName, updateVertex.clone());
      if (res.success) {
        color.setColor(selectedVertexName, updateVertex.style.fillColor);
        setErrorMsg('');
        setSelectedVertexName(updateVertex.name);
        renderChart();
      } else {
        setErrorMsg(res.message || 'update vertex failed');
      }
      return res;
    },
    [color, designerService, renderChart, selectedVertexName]
  );

  const handleDeleteVertexIcon = useCallback(
    (icon: string) => {
      const graph = designerService.graph.clone();
      let changed = false;
      graph.vertexTypes.forEach((vertex) => {
        if (vertex?.style.icon === icon) {
          vertex.style.icon = '';
          changed = true;
        }
      });
      if (changed) {
        designerService.semanticCheckAndUpdateGraph(graph);
        renderChart();
      }
    },
    [designerService, renderChart]
  );

  const handleCloseVertexPopover = useCallback(() => {
    handleVertexPopoverVisible(false);
    setErrorMsg('');
    renderChart();
  }, [handleVertexPopoverVisible, renderChart]);

  const handleCloseEdgePopover = useCallback(() => {
    handleEdgePopoverVisible(false);
    setErrorMsg('');
    renderChart();
  }, [handleEdgePopoverVisible, renderChart]);

  const handleUpdateEdge = useCallback(
    (updateEdge: Edge, edgeTypeName?: string): ValidateResult => {
      const res = designerService.updateEdge(edgeTypeName ?? selectedEdgeName, updateEdge.clone());
      if (res.success) {
        color.setColor(updateEdge.name, updateEdge.style.fillColor);
        setSelectedEdgeName(updateEdge.name);
        setErrorMsg('');
        renderChart();
      } else {
        setErrorMsg(res.message || 'update edge failed');
      }
      return res;
    },
    [color, designerService, renderChart, selectedEdgeName]
  );

  const handleGraphChartPointer = useCallback(
    (item?: ExternalNode | ExternalLink, isMoving = false) => {
      if (item) {
        if ('id' in item) {
          handleVertexPopoverVisible(true);
          handleEdgePopoverVisible(false);
          setErrorMsg('');
          const vertex = designerService?.getVertex(item.id);
          setVertex(vertex?.clone());
          setEdge(undefined);
          setSelectedVertexName(vertex?.name || '');
        } else if ('source' in item) {
          handleEdgePopoverVisible(true);
          handleVertexPopoverVisible(false);
          setErrorMsg('');
          const edge = designerService?.getEdge(item.type);
          setEdge(edge?.clone());
          setVertex(undefined);
          setSelectedEdgeName(edge?.name || '');
        }
      }
      if (isMoving) {
        handleChangePosition();
      }
    },
    [designerService, handleChangePosition, handleEdgePopoverVisible, handleVertexPopoverVisible]
  );

  const buildWarningMessage = useCallback(
    (item: Vertex | Edge) => {
      const messages: string[] = [];
      if (item) {
        if (isGlobalView) {
          if (item.usage.length > 0) {
            messages.push(
              `${'primaryId' in item ? 'Vertex' : 'Edge'} type ${item.name} is used on graph ${item.usage.join(', ')}.`
            );
            messages.push('You can only modify its attributes and visual style.');
            messages.push(
              `Modifying ${'primaryId' in item ? 'Vertex' : 'Edge'} type ${
                item.name
              } may affect loading jobs related to it.`
            );
          }
        } else {
          if (!item.isLocal) {
            messages.push('You can only modify visual style for global types.');
          }
        }
      }
      return messages;
    },
    [isGlobalView]
  );

  useImperativeHandle(
    ref,
    (): GraphResultRef => ({
      vertex,
      edge,
      schema: schemaCopy,
      graph: graphCopy,
      isAddingVertex,
      isSchemaChanged,
      handleCreateVertex,
      handleCreateEdge,
      handleCreateNodeAndEdge,
      handleUpdateVertex,
      handleUpdateEdge,
      confirmDeleteVerticesOrEdges,
      handleChangePosition,
      renderChart,
      centerGraph,
      schemaStyleChanged,
      handleSaveSchema: handleSave,
      handleDeleteVertexIcon,
      graphRef: graphRef,
      cyRef,
    })
  );

  const graphEvents: GraphEvents = useMemo(
    () => ({
      onClick: (item: ExternalNode | ExternalLink | undefined, position?: { x: number; y: number }) => {
        if (item) {
          // Single click a vertex
          handleGraphChartPointer(item);
        } else if (isAddingVertex && !item) {
          handleCreateVertex(position);
        } else {
          vertexPopoverVisible && handleCloseVertexPopover();
          edgePopoverVisible && handleCloseEdgePopover();
        }
      },
      onMouseOver: (item: ExternalNode | ExternalLink) => {
        if (!enableDelete) {
          return;
        }
        if ('id' in item) {
          graphRef.current?.displayNodeIcon(item, GRAPH_ICON.delete);
        } else if ('source' in item) {
          graphRef.current?.displayLinkIcon(item, GRAPH_ICON.delete);
        }
      },
      onMouseOut: (item: ExternalNode | ExternalLink) => {
        const globalTypes = designerService.getAllGlobalTypes();
        const showGlobalIcon =
          isSchemaGraph &&
          !isGlobalView &&
          globalTypes.length <= GlobalIconNumberLimit &&
          globalTypes.includes(item.type);
        if ('id' in item) {
          if (showGlobalIcon) {
            graphRef.current?.displayNodeIcon(item, GRAPH_ICON.global);
          } else {
            graphRef.current?.removeNodeIcon(item);
          }
        } else if ('source' in item) {
          if (showGlobalIcon) {
            graphRef.current?.displayLinkIcon(item, GRAPH_ICON.global);
          } else {
            graphRef.current?.removeLinkIcon(item);
          }
        }
      },
      onDelete: (item: ExternalNode | ExternalLink) => {
        graphRef.current?.unselectElements();
        if ('id' in item) {
          graphRef.current?.selectNodes([item]);
        } else if ('source' in item) {
          graphRef.current?.selectEdges([item]);
        }
        setDeleteItems([item]);
        setIsOpenDeleteTypeModal(true);
        setVertex(undefined);
        setEdge(undefined);
        setVertexPopoverVisible(false);
        setEdgePopoverVisible(false);
      },
      onCreateLink: (source: ExternalNode, target: ExternalNode | ExternalLink) => {
        const newEdge = handleCreateEdge(source, target);
        setSelectedEdgeName(newEdge.name);
        handleEdgePopoverVisible(true);
        handleVertexPopoverVisible(false);
        setErrorMsg('');
      },
      onCreateLinkCancelled: (source: ExternalNode, position) => {
        handleCreateNodeAndEdge(source, position);
      },
      onDragOutBound: () => {
        graphRef?.current?.fitGraph();
      },
      onDragFree: () => {
        // setTimeout(() => {
        //   graphRef.current?.centerGraph();
        // }, 200);
      },
      onPositionChange: () => {
        handleGraphChartPointer(undefined, true);
        renderChart();
      },
      onSelect: (graph: ExternalGraph) => {
        setSelectedCount(graph.nodes.length + graph.links.length);
      },
      ...props.graphEvents,
    }),
    [
      designerService,
      edgePopoverVisible,
      enableDelete,
      handleCloseEdgePopover,
      handleCloseVertexPopover,
      handleCreateEdge,
      handleCreateNodeAndEdge,
      handleCreateVertex,
      handleEdgePopoverVisible,
      handleGraphChartPointer,
      handleVertexPopoverVisible,
      isAddingVertex,
      isGlobalView,
      isSchemaGraph,
      props.graphEvents,
      renderChart,
      vertexPopoverVisible,
    ]
  );

  const rightPanelWidth = useMemo(() => {
    return (vertexPopoverVisible || edgePopoverVisible) && editorSize && editorSize.width > 1000 ? 560 : 0;
  }, [edgePopoverVisible, editorSize, vertexPopoverVisible]);

  const editorLayout = useMemo(() => {
    const defaultStyle: StyleObject = {
      position: 'absolute',
      right: '0',
      bottom: '0',
      transition: 'height 0.2s ease',
      width: '100%',
      height: vertexPopoverVisible || edgePopoverVisible ? '400px' : '0',
      padding: vertexPopoverVisible || edgePopoverVisible ? '0 8px' : '0',
      boxSizing: 'border-box',
      overflow: 'auto',
      borderTop: `1px solid ${theme.colors.divider}`,
      backgroundColor: theme.colors['background.primary'],
      zIndex: 3,
    };
    if (editorSize && editorSize.width > 1000) {
      return {
        ...defaultStyle,
        transition: 'width 0.2s ease',
        height: vertexPopoverVisible || edgePopoverVisible ? '100%' : '0',
        width: `${rightPanelWidth}px`,
        overflow: 'auto',
        borderTop: '0',
        borderLeft: `1px solid ${theme.colors.divider}`,
      } as StyleObject;
    }
    return defaultStyle;
  }, [edgePopoverVisible, editorSize, rightPanelWidth, theme.colors, vertexPopoverVisible]);

  const graphWidget = useMemo(() => {
    if (!graphCopy) {
      return;
    }
    if (graphCopy!.nodes.length + graphCopy!.links.length > GraphSizeLimit) {
      return <div>The graph&#39;s size exceeds the limitation. The graph will not be rendered.</div>;
    }

    return (
      <Graph
        baseURL={`https://${currentWorkspace?.nginx_host}`}
        ref={graphRef}
        // @ts-ignore
        parentRef={cyRef}
        {...graphEvents}
        showEdgeHandler={() => {
          return !!enableCreate;
        }}
        userUploadedIconPathPrefix={getUserUploadedIconPathPrefix(true)}
        schemaMode={enableCreate ? 'edit' : 'view'}
        schema={schemaCopy}
        graph={graphCopy!}
        id={id}
        hideContextMenu={true}
        isSchemaGraph={isSchemaGraph}
        presetNodePositions={presetNodePositions}
        hideLeftUI={true}
        hideSearch={hideSearch}
        graphName={graphName}
        onGraphChange={() => {}}
        settings={settings}
        showGhostNode={true}
        showCreateEdgeTooltip={showCreateEdgeTooltip}
        themeType={themeType}
        onSettingUpdate={(key, value) => {
          setSettings({
            ...settings,
            [key]: value,
          });
        }}
        hoverTooltipDelay={0}
        // graph widget in tools-ui contains a StyleToastContainer
        // use this props to hide the StyleToastContainer in graph widget
        insights={true}
      />
    );
  }, [
    graphCopy,
    currentWorkspace?.nginx_host,
    graphEvents,
    enableCreate,
    schemaCopy,
    id,
    isSchemaGraph,
    presetNodePositions,
    hideSearch,
    graphName,
    settings,
    showCreateEdgeTooltip,
    themeType,
  ]);

  return (
    <div
      ref={divRef as RefObject<HTMLDivElement>}
      className={css({
        height: '100%',
        width: '100%',
        boxSizing: 'content-box',
      })}
    >
      <Layer mountNode={divRef.current}>
        <div
          className={css({
            display: 'flex',
            columnGap: '8px',
            position: 'absolute',
            top: '0',
            justifyContent: enableGraphSelect ? 'space-between' : 'end',
            width: `calc(100% - ${rightPanelWidth}px)`,
            padding: '16px',
            boxSizing: 'border-box',
            transition: 'width 0.2s ease',
            zIndex: 2,
          })}
        >
          {enableGraphSelect && (
            <GraphSelect
              maxWidth="100px"
              size={SIZE.mini}
              isSchemaChanged={isSchemaChanged}
              refetchSchema={props.refetchSchema}
              enableRefresh={true}
            />
          )}
          <div
            ref={buttonContainerRef}
            className={css({
              display: 'flex',
              rowGap: '8px',
              columnGap: '8px',
              flexWrap: 'wrap',
              justifyContent: 'flex-end',
              transition: 'right 0.2s ease',
            })}
          >
            {enableUndoRedo && (
              <div
                className={css({
                  display: 'flex',
                })}
              >
                <StatefulPopover placement={PLACEMENT.bottom} triggerType={TRIGGER_TYPE.hover} content={'Undo'}>
                  <Button
                    aria-label="undo-button"
                    kind="secondary"
                    size="compact"
                    disabled={disableButton || designerService.curHistoryPointer === 0}
                    onClick={handleUndo}
                    overrides={{
                      BaseButton: {
                        style: {
                          height: '24px',
                          boxSizing: 'border-box',
                          ...expand({
                            padding: '3px',
                          }),
                        },
                      },
                    }}
                  >
                    <MdOutlineUndo size={16} />
                  </Button>
                </StatefulPopover>
                <StatefulPopover placement={PLACEMENT.bottom} triggerType={TRIGGER_TYPE.hover} content={'Redo'}>
                  <Button
                    aria-label="redo-button"
                    kind="secondary"
                    size="compact"
                    disabled={
                      disableButton ||
                      designerService.curHistoryPointer === designerService.getHistory().getHistoryRecordLength() - 1
                    }
                    onClick={handleRedo}
                    overrides={{
                      BaseButton: {
                        style: {
                          height: '24px',
                          boxSizing: 'border-box',
                          borderLeftWidth: '0',
                          ...expand({
                            padding: '3px',
                          }),
                        },
                      },
                    }}
                  >
                    <MdOutlineRedo size={16} />
                  </Button>
                </StatefulPopover>
              </div>
            )}
            {enableDelete && selectedCount > 0 && (
              <StatefulPopover
                placement={PLACEMENT.bottom}
                triggerType={TRIGGER_TYPE.hover}
                content={'Delete selection'}
              >
                <Button
                  aria-label="delete-button"
                  kind="secondary"
                  size="compact"
                  disabled={disableButton}
                  onClick={() => {
                    deleteSelectedVerticesOrEdges();
                  }}
                  overrides={{
                    BaseButton: {
                      style: {
                        height: '24px',
                        boxSizing: 'border-box',
                        borderColor: theme.colors.negative,
                        ...expand({
                          padding: '3px',
                        }),
                      },
                    },
                  }}
                >
                  <MdDeleteOutline size={16} color={theme.colors.negative} />
                </Button>
              </StatefulPopover>
            )}
            {enableCreate && selectedCount === 0 && !errorMsg && (
              <StatefulPopover
                showArrow={true}
                content={({ close }) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      borderRadius: '5px',
                      backgroundColor: theme.colors['background.primary'],
                      maxWidth: '300px',
                    }}
                  >
                    <div style={{ fontSize: '14px', fontWeight: 600, color: theme.colors['text.primary'] }}>
                      Create edge
                    </div>
                    <img
                      src={
                        themeType === 'light'
                          ? 'https://app-tools-cdn.s3.us-west-1.amazonaws.com/create-edge.gif'
                          : 'https://app-tools-cdn.s3.us-west-1.amazonaws.com/create-edge-dark.gif'
                      }
                    />
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: theme.colors['text.primary'],
                      }}
                    >
                      When you move your mouse over the grey ring around a vertex, an edge sign will appear. Simply drag
                      it to an empty space to create a new vertex and a new edge, or drag it to an existing vertex to
                      create a new edge.
                    </div>
                    <div
                      className={css({
                        display: 'flex',
                        justifyContent: 'flex-end',
                      })}
                    >
                      <Button onClick={close}>Got it!</Button>
                    </div>
                  </div>
                )}
                placement={PLACEMENT.bottomRight}
                overrides={{
                  Body: {
                    style: {
                      backgroundColor: theme.colors['background.primary'],
                    },
                  },
                  Inner: {
                    style: {
                      backgroundColor: theme.colors['background.primary'],
                    },
                  },
                  Arrow: {
                    style: {
                      backgroundColor: theme.colors['background.primary'],
                    },
                  },
                }}
              >
                <Button
                  kind="secondary"
                  size="compact"
                  disabled={disableButton}
                  overrides={{
                    BaseButton: {
                      style: {
                        boxSizing: 'border-box',
                        height: '24px',
                        display: 'flex',
                        columnGap: '4px',
                        whiteSpace: 'nowrap',
                      },
                    },
                  }}
                >
                  <FiPlus size={16} color={theme.colors['button.icon']} />
                  Create Edge
                </Button>
              </StatefulPopover>
            )}
            {enableCreate && selectedCount === 0 && !errorMsg && (
              <Button
                kind="secondary"
                size="compact"
                disabled={disableButton}
                onClick={() => {
                  if (props.handleCreateVertex) {
                    props.handleCreateVertex();
                  } else {
                    handleCreateVertex();
                  }
                }}
                overrides={{
                  BaseButton: {
                    style: {
                      boxSizing: 'border-box',
                      height: '24px',
                      display: 'flex',
                      columnGap: '4px',
                      whiteSpace: 'nowrap',
                    },
                  },
                }}
              >
                <FiPlus size={16} color={theme.colors['button.icon']} />
                Create Vertex
              </Button>
            )}
            {!isGlobalView && enableCreate && !errorMsg && (
              <GlobalTypePopover
                disabled={disableButton}
                updateGlobalTypes={(globalVertexTypes, globalEdgeTypes) => {
                  updateGlobalTypes(globalVertexTypes, globalEdgeTypes);
                  renderChart();
                  centerGraph();
                }}
              />
            )}
            {enableSave && !errorMsg && (
              <StatefulPopover placement={PLACEMENT.bottom} triggerType={TRIGGER_TYPE.hover} content={'Save schema'}>
                <Button
                  kind="secondary"
                  size="compact"
                  disabled={disableSaveButton}
                  onClick={() => handleSave(true)}
                  overrides={{
                    BaseButton: {
                      style: {
                        height: '24px',
                        width: '24px',
                        boxSizing: 'border-box',
                        ...expand({
                          padding: '0px',
                        }),
                      },
                    },
                  }}
                  aria-label="save-schema"
                >
                  {saveSchemaLoading ? <Spinner $size={'20px'} $borderWidth={'2px'} /> : <MdSave size={20} />}
                </Button>
              </StatefulPopover>
            )}
          </div>
        </div>
      </Layer>
      {errorMsg ? (
        <div
          className={css({
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.colors['background.tertiary.a'],
            padding: '10px',
          })}
        >
          {errorMsg}
        </div>
      ) : (
        <div
          className={css({
            height: '100%',
            width: '100%',
            transition: 'height 0.2s ease',
            overflow: 'hidden',
            cursor: isAddingVertex ? 'copy' : 'default',
            backgroundColor: theme.colors['background.tertiary.a'],
          })}
        >
          {(!graphCopy || graphCopy.nodes.length + graphCopy.links.length === 0) && (
            <div
              className={css({
                width: '100%',
                height: '100%',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                pointerEvents: 'none',
                zIndex: 1,
              })}
            >
              <EmptySchema
                isLoading={false}
                enableCreate={enableCreate}
                enableSave={enableSave}
                // 1. enableGraphSelect
                // 2. enableCreate
                // 3. isSchemaGraph
                // 4. current.workspace is read/write
                // 5. graphNames only contain global graph
                showCreateNewGraph={
                  enableGraphSelect &&
                  enableCreate &&
                  isSchemaGraph &&
                  currentWorkspace?.is_rw &&
                  graphNames.length === 1 &&
                  graphNames[0] === GLOBAL_GRAPH_NAME
                }
              />
            </div>
          )}
          {saveSchemaLoading && (
            <div
              className={css({
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                backgroundColor: 'rgba(0,0,0,0.25)',
                zIndex: 10,
              })}
            >
              <Spinner $size={'40px'} $borderWidth={'6px'} $color={theme.colors.primary800} />
            </div>
          )}
          {graphWidget}
        </div>
      )}
      <ConfirmModal
        header="Warning"
        body={deleteWarning}
        open={!!deleteWarning}
        confirmLabel="OK"
        onConfirm={() => {
          setDeleteWarning(undefined);
        }}
      />
      <ConfirmModal
        header="Warning"
        body={saveWarning}
        open={!!saveWarning}
        confirmLabel="Confirm"
        onConfirm={async () => {
          setSaveWarning(undefined);
          const graphStyle = designerService?.getDBGraphStyleJson();
          if (schemaChangeJobs.length > 0) {
            await saveSchemaAsync(schemaChangeJobs);
          }
          if (schemaStyleChanged()) {
            await saveSchemaStyleAsync(graphStyle);
          }
          designerService.reset();
          renderChart();
        }}
        onCancel={() => {
          setSaveWarning(undefined);
        }}
      />
      <DeleteTypeModal
        isOpen={isOpenDeleteTypeModal}
        types={deleteItems.map((item) => item.type)}
        onClose={() => {
          setIsOpenDeleteTypeModal(false);
          setDeleteItems([]);
        }}
        onConfirm={() => {
          setIsOpenDeleteTypeModal(false);
          confirmDeleteVerticesOrEdges(deleteItems);
          setDeleteItems([]);
        }}
      />
      {enableEditor && (
        <div className={css(editorLayout)}>
          {vertex && (
            <VertexEditor
              initVertex={vertex}
              isOpen={vertexPopoverVisible}
              onClose={handleCloseVertexPopover}
              onSave={(newVertex) => handleUpdateVertex(newVertex, selectedVertexName)}
              readOnly={(!isGlobalView && !vertex?.isLocal) || (isGlobalView && vertex?.usage.length > 0)}
              attributeReadOnly={!isGlobalView && !vertex?.isLocal}
              warningMessage={buildWarningMessage(vertex)}
              onDeleteVertexIcon={handleDeleteVertexIcon}
            />
          )}
          {edge && (
            <EdgeEditor
              initEdge={edge}
              isOpen={edgePopoverVisible}
              onClose={handleCloseEdgePopover}
              onSave={(newEdge) => handleUpdateEdge(newEdge, selectedEdgeName)}
              readOnly={(!isGlobalView && !edge?.isLocal) || (isGlobalView && edge?.usage.length > 0)}
              attributeReadOnly={!isGlobalView && !edge?.isLocal}
              warningMessage={buildWarningMessage(edge)}
            />
          )}
        </div>
      )}
      {unsavedWarning && enableCreate && (
        <div
          className={css({
            width: `calc(90% - ${(buttonGroupSize?.width ?? 0) + 8}px)`,
            position: 'absolute',
            left: '16px',
            top: enableGraphSelect ? '48px' : '16px',
            pointerEvents: 'none',
          })}
        >
          <ErrorMessage message={unsavedWarning} />
        </div>
      )}
      {isLoadingSchemaChangeJobs && (
        <div
          className={css({
            position: 'absolute',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 10,
          })}
        >
          <Spinner $size={'40px'} $borderWidth={'6px'} $color={theme.colors.primary800} />
        </div>
      )}
    </div>
  );
});

export default GraphResult;
