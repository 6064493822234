import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { PiWarningCircleFill } from 'react-icons/pi';
import { expand } from 'inline-style-expand-shorthand';
import AddCloudProvider from '@/pages/admin/settings/cloud_provider/AddCloudProvider';
import { useMutation, useQueryClient } from 'react-query';
import { deleteProvider } from '@/pages/admin/settings/cloud_provider/api';
import { TableContainer } from '@/lib/styled';
import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { TableBuilderColumn } from 'baseui/table-semantic';
import { actionColumnOverrides } from '@/components/table';
import StatusIndicator, { isErrorStatus } from '@/pages/admin/settings/cloud_provider/StatusIndicator';
import { Result } from '@/lib/type';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { getErrorMessage } from '@/utils/utils';
import { useQueryCPs } from '@/pages/admin/settings/cloud_provider/hook';
import clsx from 'clsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import IconButton from '@/components/IconButton';
import { Overflow } from 'baseui/icon';
import { Body2 } from '@tigergraph/app-ui-lib/typography';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { CloudProviderT } from '@/pages/admin/settings/cloud_provider/types';
import { PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import { StatefulTipsPopover } from '@/components/tipsPopover';
import CloudProviderDetail from '@/pages/admin/settings/cloud_provider/CloudProviderDetail';
import { KIND } from 'baseui/button';
import { getRegionName } from '@/pages/admin/settings/cloud_provider/region';
import Empty from '@/pages/admin/settings/cloud_provider/Empty';

export default function CloudProviders() {
  const [isOpen, setIsAddFormOpen] = useState(false);
  const [css, theme] = useStyletron();
  const queryClient = useQueryClient();

  const cloudProvidersQuery = useQueryCPs();
  const data = cloudProvidersQuery.data?.Result || [];
  const sortedData = data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [cpToDelete, setCpToDelete] = useState<CloudProviderT | null>(null);

  const deleteProviderMutation = useMutation<Result<void>, AxiosError, string>(
    async (id) => {
      const res = await deleteProvider(id);
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['cloud-providers']);
      },
    }
  );

  const onDelete = (id: string) => {
    queryClient.setQueryData(['cloud-providers'], (data: Result<CloudProviderT[]> | undefined) => {
      if (!data) {
        return data;
      }
      return {
        ...data,
        Result: data.Result?.map((cp) => {
          if (cp.id === cpToDelete!.id) {
            return { ...cp, status: 'deleting' };
          }
          return cp;
        }),
      } as any;
    });
    const promise = deleteProviderMutation.mutateAsync(id, {
      onError: () => {
        queryClient.invalidateQueries(['cloud-providers']);
      },
    });

    toast.promise(
      promise,
      {
        loading: 'Deleting cloud provider',
        success: () => 'Cloud provider deletion request sent successfully',
        error: (err) => getErrorMessage(err),
      },
      {}
    );
  };

  return (
    <>
      {sortedData.length === 0 ? (
        <Empty onClickAdd={() => setIsAddFormOpen(true)} />
      ) : (
        <div className={css({ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: '100%' })}>
          <Button
            onClick={() => setIsAddFormOpen(true)}
            overrides={{ BaseButton: { style: { ...expand({ padding: '4px 16px' }) } } }}
          >
            <MdAdd size={20} />
            <span className={css({ marginLeft: '8px', ...theme.typography['LabelSmall'] })}>Cloud Provider</span>
          </Button>
          <TableContainer $style={{ flex: '1 1 0', width: '100%', ...expand({ padding: '16px 0px' }) }}>
            <TableBuilder data={sortedData}>
              <TableBuilderColumn header="Cloud Provider Name" id="name">
                {(row) => (
                  <div className={css({ display: 'flex', alignItems: 'center', gap: '4px' })}>
                    {row.name}
                    <CloudProviderDetail cp={row as CloudProviderT} />
                  </div>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn header="Status" id="status">
                {(row) => (
                  <div className={css({ display: 'flex', alignItems: 'center', gap: '4px' })}>
                    <StatusIndicator status={row.status} />
                    {!!row.last_error && isErrorStatus(row.status) && (
                      <StatefulTipsPopover
                        triggerType={TRIGGER_TYPE.hover}
                        content={row.last_error}
                        ignoreBoundary={true}
                        placement={PLACEMENT.top}
                        overrides={{
                          Arrow: {
                            style: {
                              background:
                                'linear-gradient(0deg, rgba(248, 235, 235, 0.40) 0%, rgba(248, 235, 235, 0.40) 100%), #FFF',
                            },
                          },
                          Body: {
                            style: {
                              maxWidth: '420px',
                              wordWrap: 'break-word',
                              boxShadow: '-2px -2px 6px 0px rgba(0, 0, 0, 0.10), 2px 2px 6px 0px rgba(0, 0, 0, 0.10)',
                              BorderRadius: '5px',
                              background:
                                'linear-gradient(0deg, rgba(248, 235, 235, 0.40) 0%, rgba(248, 235, 235, 0.40) 100%), #FFF',
                            },
                          },
                          Inner: {
                            style: {
                              BorderRadius: '5px',
                              background:
                                'linear-gradient(0deg, rgba(248, 235, 235, 0.40) 0%, rgba(248, 235, 235, 0.40) 100%), #FFF',
                              color: '#A43030',
                            },
                          },
                        }}
                      >
                        <div>
                          <PiWarningCircleFill color="#B93535" />
                        </div>
                      </StatefulTipsPopover>
                    )}
                  </div>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn header="Provider" id="provider">
                {(row) => row.platform}
              </TableBuilderColumn>
              <TableBuilderColumn header="Region" id="region">
                {(row) => getRegionName(row as CloudProviderT)}
              </TableBuilderColumn>
              <TableBuilderColumn overrides={actionColumnOverrides} header="Actions">
                {(row) => (
                  <div
                    className={clsx(
                      css({
                        display: 'flex',
                        gap: '6px',
                      })
                      // 'hoverToShow'
                    )}
                  >
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <IconButton
                          title="Actions"
                          $style={{
                            border: '1px solid #AAB5BF',
                            padding: '2px 3px',
                          }}
                        >
                          <Overflow size={16} />
                        </IconButton>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-56">
                        <DropdownMenuItem
                          onClick={() => {
                            setShowDeleteConfirm(true);
                            setCpToDelete(row as CloudProviderT);
                          }}
                          disabled={['deleted', 'deleting', 'upgrading', 'provisioning'].includes(row.status)}
                        >
                          <Body2>Delete</Body2>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                )}
              </TableBuilderColumn>
            </TableBuilder>
          </TableContainer>
        </div>
      )}
      <AddCloudProvider isOpen={isOpen} onClose={() => setIsAddFormOpen(false)} />
      <ConfirmDeleteProvider
        isOpen={showDeleteConfirm}
        onCancel={() => setShowDeleteConfirm(false)}
        onConfirm={() => {
          setShowDeleteConfirm(false);
          onDelete(cpToDelete!.id);
        }}
        name={cpToDelete?.name || ''}
      />
    </>
  );
}

type ConfirmProps = {
  isOpen: boolean;
  name: string;
  onCancel: () => void;
  onConfirm: () => void;
};

function ConfirmDeleteProvider({ isOpen, onCancel, onConfirm, name }: ConfirmProps) {
  return (
    <Modal onClose={onCancel} isOpen={isOpen}>
      <ModalHeader>Delete the cloud provider?</ModalHeader>
      <ModalBody>
        Are you sure to delete cloud provider <strong>{name}</strong>?
      </ModalBody>
      <ModalFooter>
        <ModalButton kind={KIND.secondary} onClick={onCancel}>
          Cancel
        </ModalButton>
        <ModalButton onClick={onConfirm}>Confirm</ModalButton>
      </ModalFooter>
    </Modal>
  );
}
