import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { useMutation } from 'react-query';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Navigation } from 'baseui/side-navigation';
import { HeadingMedium, Body1 } from '@tigergraph/app-ui-lib/typography';

import { logoutClearance } from '@/utils/utils.ts';

import { TermsConditions } from './TermsConditions';
import { PrivacyPolicy } from './PrivacyPolicy';
import Container from './Container';
import { InlineBlock } from '@/components/landing/Style.tsx';
import { axiosOrgService } from '@/lib/network.ts';
import { useQueryParams } from '@/components/landing/hooks.ts';
import { Modal } from '@tigergraph/app-ui-lib/modal';
import { showToast } from '@/components/styledToasterContainer';
import { getCallbackURL } from '@/lib/auth.ts';
import { useNavigate } from 'react-router';

enum ItemKey {
  TermsConditions = 'termsConditions',
  PrivacyPolicy = 'privacyPolicy',
}

// Update this version when the terms and conditions are updated
const version: string = '1.0.0';
const initialVersion: string = '1.0.0';

export const isTermsAccepted = (idToken: IdToken | undefined) => {
  return !!(
    (idToken?.metadata?.accepted_tos && version === initialVersion) ||
    idToken?.metadata?.accepted_toc_version === version
  );
};

export default function LegalTerms({
  isViewMode = false,
  showModal = true,
  onClose,
}: {
  isViewMode?: boolean;
  showModal?: boolean;
  onClose?: () => void;
}) {
  const { loginWithRedirect, logout } = useAuth0();
  const [css, theme] = useStyletron();
  const queryParams = useQueryParams();
  const [activeItem, setActiveItem] = useState<string>(ItemKey.TermsConditions);
  const { getIdTokenClaims } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isViewMode) {
      return;
    }
    getIdTokenClaims().then((idToken) => {
      if (!isTermsAccepted(idToken)) {
        return;
      }

      navigate('/groups');
    });
  }, [getIdTokenClaims, navigate, isViewMode]);

  async function updateToS() {
    try {
      const response = await axiosOrgService.patch('/users/me/metadata', {
        accepted_tos: true,
        accepted_toc_version: version,
      });

      return response.data;
    } catch (err: any) {
      console.log(err);
      return Promise.reject(err.response.data);
    }
  }

  const userMutation = useMutation(
    async () => {
      return await updateToS();
    },
    {
      onSuccess: (res) => {
        const org_id = queryParams.get('org_id') || queryParams.get('organization');
        if (res.user_metadata?.accepted_tos) {
          if (org_id) {
            loginWithRedirect({
              authorizationParams: {
                'ext-origin': window.location.origin,
                organization: org_id,
              },
            });
          } else {
            loginWithRedirect({
              authorizationParams: {
                'ext-origin': window.location.origin,
              },
            });
          }
        } else {
          showToast({
            kind: 'negative',
            message: 'Network Error: Please accept again.',
          });
        }
      },
      onError: (err: AxiosError) => {
        console.log(err);
        showToast({
          kind: 'negative',
          message: err?.message,
        });
      },
    }
  );

  return (
    <Modal
      closeable={isViewMode}
      onClose={() => onClose && onClose()}
      isOpen={showModal}
      overrides={{
        Dialog: {
          style: {
            width: 'calc(100% - 320px)',
            minWidth: '700px',
            maxWidth: '1200px',
          },
        },
      }}
    >
      <Container>
        <InlineBlock
          style={{
            height: '623px',
            paddingRight: '24px',
            paddingLeft: '24px',
            paddingBottom: isViewMode ? '24px' : '0px',
          }}
        >
          <Navigation
            items={[
              {
                title: 'Terms & Conditions',
                itemId: ItemKey.TermsConditions,
              },
              {
                title: 'Privacy Policy',
                itemId: ItemKey.PrivacyPolicy,
              },
            ]}
            activeItemId={activeItem}
            onChange={({ event, item }) => {
              event.preventDefault();
              setActiveItem(item.itemId);
            }}
            overrides={{
              Root: {
                style: {
                  height: '100%',
                  borderRightColor: theme.colors.divider,
                  borderRightWidth: '1px',
                  borderRightStyle: 'solid',
                  marginRight: '22px',
                  paddingRight: '24px',
                },
              },
              NavItem: {
                style: ({ $active }) => {
                  return {
                    backgroundColor: 'transparent',
                    borderLeftColor: 'transparent',
                    backgroundImage: 'none',
                    borderWidth: '0',
                    color: $active ? theme.colors['text.link'] : theme.colors['text.secondary'],
                    ':hover': {
                      color: theme.colors['text.link.pressed'],
                    },
                    fontFamily: 'Urbanist',
                    fontWeight: $active ? 600 : 500,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: '16px',
                    paddingLeft: 0,
                  };
                },
              },
            }}
          />
          {activeItem === ItemKey.TermsConditions && (
            <div style={{ width: '813px' }}>
              <HeadingMedium as="div" marginBottom="8px">
                Terms & Conditions
              </HeadingMedium>
              <Body1 color={theme.colors['text.secondary']} marginBottom="16px">
                Please read these terms & conditions carefully, and accept to continue.
              </Body1>
              <div
                className={css({
                  height: 'calc(100% - 88px)',
                  overflow: 'auto',
                })}
              >
                <TermsConditions />
              </div>
            </div>
          )}

          {activeItem === ItemKey.PrivacyPolicy && (
            <div style={{ width: '813px' }}>
              <HeadingMedium as="div" marginBottom="8px">
                Privacy Policy
              </HeadingMedium>
              <Body1 color={theme.colors['text.secondary']} marginBottom="16px">
                Please read these privacy policy carefully, and accept to continue.
              </Body1>
              <div
                className={css({
                  height: 'calc(100% - 88px)',
                  overflow: 'auto',
                })}
              >
                <PrivacyPolicy />
              </div>
            </div>
          )}
        </InlineBlock>
        {!isViewMode && (
          <InlineBlock
            style={{
              gap: '16px',
              justifyContent: 'flex-end',
              paddingTop: '16px',
              paddingRight: '24px',
              paddingLeft: '24px',
              borderTop: `1px solid ${theme.colors.divider}`,
              marginTop: '16px',
            }}
          >
            <Button
              size="large"
              kind="secondary"
              disabled={userMutation.isLoading}
              onClick={() => {
                logoutClearance();
                logout({
                  logoutParams: { returnTo: getCallbackURL() },
                });
              }}
            >
              Decline
            </Button>
            <Button
              size="large"
              onClick={() => userMutation.mutate()}
              disabled={userMutation.isLoading}
              isLoading={userMutation.isLoading}
            >
              Accept
            </Button>
          </InlineBlock>
        )}

        {/*{hasError && (*/}
        {/*  <NegativeNotification*/}
        {/*    overrides={{*/}
        {/*      Body: {*/}
        {/*        style: {*/}
        {/*          position: 'absolute',*/}
        {/*        },*/}
        {/*      },*/}
        {/*    }}*/}
        {/*    onClose={closeError}*/}
        {/*  >*/}
        {/*    {errMsg}*/}
        {/*  </NegativeNotification>*/}
        {/*)}*/}
      </Container>
    </Modal>
  );
}
