import { parseMixed } from '@lezer/common';
import { styleTags, tags as t } from '@lezer/highlight';
import { parser as queryParser } from './query.ts';
import { parser as scriptParser } from './script.grammar.ts';
import {
  LRLanguage,
  LanguageSupport,
  StreamLanguage,
  continuedIndent,
  indentNodeProp,
  indentService,
  syntaxTree,
} from '@codemirror/language';
import { autocompletion } from '@codemirror/autocomplete';
import { Schema } from '@tigergraph/tools-ui/graph/type';
import {
  completeFromAccumTypes,
  completeFromBaseTypes,
  completeFromIdents,
  completeFromKeywords,
  completeFromSchema,
  completeInLoadingStmts,
} from '@/pages/editor/GSQL/complete.ts';
import { kws as scriptKws } from '@/pages/editor/GSQL/scriptKw.ts';
import { baseTypes } from '@/pages/editor/GSQL/queryKw.ts';
import { cypher } from '@/pages/editor/GSQL/cypher/cypher.ts';
import { cypherCompletions } from '@/pages/editor/GSQL/cypher/cypher-extension.ts';
import { editorSupportField } from '@/pages/editor/GSQL/cypher/cypher-state-definitions.ts';

const loadingStmts = ['LoadStmt', 'DefineFilename', 'DefineHeader', 'DeleteVertexStmt', 'DeleteEdgeStmt'];

const cypherStreamParser = StreamLanguage.define(cypher).parser;

const mixedScriptParser = scriptParser.configure({
  wrap: parseMixed((node) => {
    if (node.name == 'QueryText') {
      for (let prev = node.node.prevSibling; prev; prev = prev.prevSibling) {
        if (prev.name === 'CreateQueryModifiers' && prev.getChild('OPENCYPHER')) {
          return { parser: cypherStreamParser };
        }
      }

      return { parser: queryParser };
    }

    return null;
  }),
  props: [
    indentNodeProp.add({
      LoadingJobBlock: continuedIndent({ except: /}/ }),
      'DefineFilename DefineHeader': continuedIndent({}),
      [loadingStmts.join(' ')]: continuedIndent({}),
    }),
    styleTags({
      [baseTypes.join(' ')]: t.typeName,
      'TRUE FALSE': t.bool,
      NULL: t.null,
      Number: t.number,
      [scriptKws.join(' ')]: t.keyword,
      String: t.string,
      Identifier: t.name,
      LineComment: t.lineComment,
      BlockComment: t.blockComment,
      '.': t.derefOperator,
      '( )': t.paren,
      '{ }': t.brace,
      '[ ]': t.squareBracket,
      '< >': t.angleBracket,
    }),
  ],
});

export interface GSQLConfig {
  schema?: Schema;
}

export function gsql(config: GSQLConfig = {}) {
  const language = LRLanguage.define({
    name: 'gsql',
    parser: mixedScriptParser,
    languageData: {
      commentTokens: { line: '//', block: { open: '/*', close: '*/' } },
      closeBrackets: { brackets: ['(', '[', '{', "'", '"', '`', '<'] },
    },
  });

  return new LanguageSupport(language, [
    autocompletion({
      override: [
        completeFromKeywords(),
        completeFromIdents(),
        completeFromAccumTypes(),
        completeFromBaseTypes(),
        completeFromSchema(),
        completeInLoadingStmts(),
        cypherCompletions,
      ],
    }),
    editorSupportField,
    indentService.of((context, pos) => {
      // For cypher, keep indentation of the last line
      const at = syntaxTree(context.state).resolveInner(pos, -1);
      // not in a cypher block
      if (at?.parent?.name !== 'Document') {
        return undefined;
      }

      const lastLineText = context.lineAt(pos, -1).text;
      const spaces = lastLineText.match(/^\s*/);
      return spaces ? spaces[0].length : undefined;
    }),
  ]);
}
