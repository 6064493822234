const suppressWarnings = [
  'If you want to write it to the DOM, pass a string instead',
  'Support for defaultProps will be removed from function components in a future major release. Use JavaScript default parameters instead',
  'ReactDOM.render is no longer supported in React 18. Use createRoot instead. Until you switch to the new AP',
];

const originalConsoleError = window.console.error;
window.console.error = function (...args: any[]) {
  for (let arg of args) {
    if (typeof arg === 'string' && suppressWarnings.some((warning) => arg.includes(warning))) {
      return;
    }
  }
  originalConsoleError.apply(this, args);
};
