import { cypherFuncs } from '@/pages/editor/GSQL/cypher/cypher';
import { getStateEditorSupport } from '@/pages/editor/GSQL/cypher/cypher-state-selectors';
import { schemaCache } from '@/pages/editor/GSQL/schemaCache';
import { findCypherNodeUpwards, getQueryGraphName, getQueryParams } from '@/pages/editor/GSQL/util';
import { CompletionContext, CompletionResult } from '@codemirror/autocomplete';
import { syntaxTree } from '@codemirror/language';
import { EditorSupportSchema } from '@neo4j-cypher/editor-support';

export const cypherCompletions = async (context: CompletionContext): Promise<CompletionResult | null> => {
  const at = syntaxTree(context.state).resolveInner(context.pos, -1);
  const cypherNode = findCypherNodeUpwards(at);
  if (!cypherNode) {
    return null;
  }

  const cypherStartPos = cypherNode.from + 2; // to skip the leading left brace and the new line character
  const cypherEndPos = cypherNode.to - 1; // to skip the trailing right brace
  const cypherText = context.state.doc.sliceString(cypherStartPos, cypherEndPos);
  const curNodeText = context.state.doc.sliceString(at.from, at.to);
  if (!cypherText) {
    return null;
  }

  const editorSupport = getStateEditorSupport(context.state);
  const graphName = getQueryGraphName(context.state.doc, at);
  const schemaComp = await schemaCache.getSchemaCompletion(graphName);
  let schema: EditorSupportSchema = {};
  const queryParams = getQueryParams(context.state.doc, cypherNode.parent!);
  schema.parameters = Array.from(queryParams.keys());
  schema.functions = cypherFuncs.map((name) => ({ name, signature: '' }));
  if (schemaComp) {
    schema = {
      ...schema,
      labels: schemaComp.getVertexCompletions().map((v) => `:${v.label}`),
      relationshipTypes: schemaComp.getEdgeCompletions().map((e) => `:${e.label}`),
      propertyKeys: schemaComp.getAllAttrCompletions().map((a) => a.label),
    };
  }
  editorSupport.setSchema(schema);
  editorSupport.update(cypherText);

  // @ts-ignore
  const { line, column } = editorSupport.positionConverter.toRelative(at.from - cypherStartPos);
  const completion = editorSupport.getCompletion(line, column, true);
  let { items, from } = completion;
  const completions = items
    .filter((item) => !(item.type === 'variable' && item.content === curNodeText))
    .map(({ type, view, content, postfix }) => ({
      type,
      label: view,
      apply: content,
      detail: postfix,
    }));
  const word = context.matchBefore(/\w*/);
  const triggerStr = ['.', '(', '[', '{', '$', ':'];
  let cypherCompletions = null;
  if (triggerStr.includes(curNodeText) || (word && !(word.from == word.to && !context.explicit))) {
    // @ts-ignore
    let fromPos = editorSupport.positionConverter.toAbsolute(from.line, from.column) + cypherStartPos;
    fromPos = Math.max(at.from, fromPos);
    cypherCompletions = {
      // line is 1 based, column is 0 based
      from: fromPos + (triggerStr.includes(curNodeText) && curNodeText !== ':' ? 1 : 0),
      options: completions,
      // getMatch: () => [],
    };
  }

  // @ts-ignore
  return cypherCompletions;
};
