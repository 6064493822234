import { editorSupportField } from '@/pages/editor/GSQL/cypher/cypher-state-definitions';
import { EditorState } from '@codemirror/state';
import { CypherEditorSupport } from '@neo4j-cypher/editor-support';

export const getStateValue = (state: EditorState) => state.doc.toString();
export const getStateEditorSupport = (state: EditorState) =>
  state.field(editorSupportField, false) as CypherEditorSupport;
export const getStatePositionAbsoluteForLineColumn = (
  state: EditorState,
  { line, column }: { line: number; column: number }
) => state.doc.line(line).from + column;
