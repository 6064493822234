import { StateField } from '@codemirror/state';
import { CypherEditorSupport } from '@neo4j-cypher/editor-support';

export const editorSupportField = StateField.define({
  create() {
    return new CypherEditorSupport('');
  },
  update(editorSupport, tr) {
    return editorSupport;
  },
});
