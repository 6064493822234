// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  whitespace = 254,
  LineComment = 1,
  BlockComment = 2,
  String = 3,
  Number = 4,
  ParenL = 5,
  ParenR = 6,
  BraceL = 7,
  BraceR = 8,
  BracketL = 9,
  BracketR = 10,
  Dot = 11,
  Identifier = 12,
  GRAPH = 13,
  PRINT = 14,
  LOG = 15,
  RETURNS = 16,
  EXCEPTION = 17,
  SELECT = 18,
  FROM = 19,
  WHERE = 20,
  SAMPLE = 21,
  WHEN = 22,
  ACCUM = 23,
  HAVING = 25,
  ASC = 26,
  DESC = 27,
  LIMIT = 28,
  DELETE = 29,
  INSERT = 30,
  UPDATE = 31,
  RANGE = 32,
  TYPEDEF = 33,
  TUPLE = 34,
  ANY = 35,
  API = 36,
  AS = 37,
  BY = 38,
  DISTINCT = 39,
  ISEMPTY = 40,
  LOADACCUM = 41,
  PER = 42,
  PINNED = 43,
  POST_ACCUM = 44,
  TARGET = 45,
  FOR = 46,
  GROUP = 47,
  MAP = 48,
  ORDER = 49,
  SYNTAX = 50,
  CURRENT_DATE = 51,
  CURRENT_TIME = 52,
  CURRENT_TIMESTAMP = 53,
  FILTER = 54,
  PRIMARY_ID = 55,
  VALUES = 56,
  INTO = 57,
  TO = 58,
  OFFSET = 59,
  TO_CSV = 60,
  STEP = 61,
  COALESCE = 62,
  AND = 63,
  OR = 64,
  NOT = 65,
  LIKE = 66,
  BETWEEN = 67,
  IS = 68,
  IN = 69,
  INTERSECT = 70,
  MINUS = 71,
  UNION = 72,
  ESCAPE = 73,
  GSQL_UINT_MAX = 74,
  GSQL_INT_MAX = 75,
  GSQL_INT_MIN = 76,
  TO_DATETIME = 77,
  TRUE = 78,
  FALSE = 79,
  NULL = 80,
  IF = 81,
  THEN = 82,
  ELSE = 83,
  WHILE = 84,
  DO = 85,
  FOREACH = 86,
  END = 87,
  CASE = 88,
  CONTINUE = 89,
  BREAK = 90,
  TRY = 91,
  RAISE = 92,
  RETURN = 93,
  INT = 94,
  UINT = 95,
  FLOAT = 96,
  DOUBLE = 97,
  STRING = 98,
  BOOL = 99,
  VERTEX = 100,
  EDGE = 101,
  JSONOBJECT = 102,
  JSONARRAY = 103,
  SET = 104,
  BAG = 105,
  FILE = 106,
  DATETIME = 107,
  SumAccum = 108,
  MaxAccum = 109,
  MinAccum = 110,
  AvgAccum = 111,
  OrAccum = 112,
  AndAccum = 113,
  BitwiseOrAccum = 114,
  BitwiseAndAccum = 115,
  ListAccum = 116,
  SetAccum = 117,
  BagAccum = 118,
  MapAccum = 119,
  HeapAccum = 120,
  GroupByAccum = 121,
  ArrayAccum = 122,
  COUNT = 123,
  MAX = 124,
  MIN = 125,
  AVG = 126,
  SUM = 127,
  GSQLQuery = 128,
  QueryBodyStmts = 129,
  TypedefStmt = 130,
  Tuple = 133,
  BaseType = 134,
  TupleType = 136,
  VSetVarDeclStmt = 137,
  Seeds = 139,
  VertexSet = 140,
  AssignmentStmt = 142,
  Expression = 143,
  GlobalAccumIdent = 144,
  LocalAccumIdent = 145,
  MemberExpression = 146,
  PropertyName = 147,
  CallExpression = 148,
  ArgList = 149,
  ParenthesizedExpression = 150,
  Aggregator = 151,
  BinaryExpression = 155,
  CompareOp2 = 161,
  CompareOp1 = 162,
  Constant = 165,
  Bool = 166,
  GAccumAssignStmt = 169,
  LAccumAssignStmt = 170,
  DeclStmt = 171,
  BaseDeclStmt = 172,
  AccumDeclStmt = 173,
  AccumType = 174,
  Type = 175,
  ElementType = 176,
  FileDeclStmt = 177,
  FuncCallStmt = 178,
  CaseStmt = 179,
  ForEachStmt = 180,
  ForEachControl = 181,
  RaiseStmt = 183,
  TryStmt = 184,
  LogStmt = 185,
  SelectStmt = 186,
  GSQLSelectClause = 187,
  FromClause = 188,
  PathPattern = 189,
  StepVertexSet = 190,
  MapLiteral = 191,
  PathEdgePattern = 192,
  GSQLEdgePattern = 193,
  RelationshipTypes = 194,
  RangeLiteral = 195,
  SampleClause = 196,
  WhereClause = 197,
  AccumClause = 198,
  DmlSubStmtList = 199,
  GAccumAccumStmt = 200,
  LocalVarDeclStmt = 202,
  LAccumAccumStmt = 203,
  DmlSubCaseStmt = 204,
  DmlSubWhileStmt = 205,
  DmlSubIfStmt = 206,
  DmlSubForEachStmt = 207,
  InsertStmt = 208,
  DmlSubDeleteStmt = 209,
  PostAccumClause = 210,
  HavingClause = 211,
  OrderClause = 212,
  LimitClause = 213,
  SQLSelectClause = 214,
  GroupByClause = 215,
  PrintStmt = 216,
  ReturnStmt = 217,
  WhileStmt = 218,
  IfStmt = 219,
  DeleteStmt = 220,
  UpdateStmt = 221
