// doc: https://docs.tigergraph.com/gsql-ref/4.1/appendix/keywords-and-reserved-words
export const kws = [
  'ACCUM',
  'ADD',
  'ALL',
  'ALLOCATE',
  'ALTER',
  'ANY',
  'ASC',
  'BAG',
  'BETWEEN',
  'BOOL',
  'BY',
  'CALL',
  'COALESCE',
  'COMPRESS',
  'COUNT',
  'CREATE',
  'KAFKA',
  'S3',
  'DATETIME_ADD',
  'DATETIME_SUB',
  'DATETIME_DIFF',
  'DATETIME_TO_EPOCH',
  'DATETIME_FORMAT',
  'DELETE',
  'DESC',
  'DISTRIBUTED',
  'DROP',
  'DATETIME',
  'DOUBLE',
  'EPOCH_TO_DATETIME',
  'EDGE',
  'ESCAPE',
  'FALSE',
  'FILE',
  'FIXED_BINARY',
  'FLOAT',
  'FROM',
  'GLOBAL',
  'GRAPH',
  'GROUP',
  'HAVING',
  'HEADER',
  'INDEX',
  'INPUT_LINE_FILTER',
  'INSERT',
  'INT',
  'INTERPRET',
  'INTO',
  'ISEMPTY',
  'JOB',
  'LIKE',
  'LIMIT',
  'NOT',
  'NOW',
  'NULL',
  'OPENCYPHER',
  'OR',
  'ORDER',
  'PRIMARY_ID',
  'PRIMARY',
  'DISCRIMINATOR',
  'KEY',
  'QUERY',
  'REDUCE',
  'REPLACE',
  'RETURNS',
  'SAMPLE',
  'SELECT',
  'SELECTVERTEX',
  'SET',
  'STRING',
  'SUM',
  'TEMP_TABLE',
  'TO',
  'TO_CSV',
  'TRIM',
  'TRUE',
  'TUPLE',
  'UINT',
  'UPDATE',
  'VALUES',
  'VERTEX',
  'WHERE',
  'AVG',
  'ABORT',
  'ACL',
  'API',
  'ATTRIBUTE',
  'CONCAT',
  'DATA',
  'DATA_SOURCE',
  'DEFINE',
  'DIRECTED',
  'EMPTY',
  'EXPORT',
  'FILENAME',
  'GET',
  'GRANT',
  'IMPORT',
  'INSTALL',
  'JSON',
  'LOADING',
  'LOCAL',
  'LS',
  'MAX',
  'MIN',
  'MINUS',
  'OVERWRITE',
  'OWNER',
  'PAIR',
  'PASSWORD',
  'PRIVILEGE',
  'PUT',
  'READ',
  'REJECT_LINE_RULE',
  'RESUME',
  'REVOKE',
  'ROLE',
  'RUN',
  'SCHEMA',
  'SCHEMA_CHANGE',
  'SECONDARY_ID',
  'SECRET',
  'SHOW',
  'SPLIT',
  'STATS',
  'STATUS',
  'STORE',
  'SUBSTR',
  'SYNTAX',
  'TAG',
  'TEMPLATE',
  'TOKEN',
  'TOKEN_LEN',
  'TOKENBANK',
  'UNDIRECTED',
  'USE',
  'USER',
  'FOR',
  'LOAD',
  'USING',
  'TAGS',
  'AND',
  'NUMERIC',
  'TYPEDEF',
];

const tokenFuncs = [
  // The token functions in this section are used in the attribute expressions in the destination clause or as part of a condition in the WHERE clause of a LOAD statement.
  'gsql_reverse',
  'gsql_concat',
  'gsql_uuid_v4',
  'gsql_split_by_space',
  'gsql_substring',
  'gsql_find',
  'gsql_length',
  'gsql_replace',
  'gsql_regex_replace',
  'gsql_regex_match',
  'gsql_to_bool',
  'gsql_to_uint',
  'gsql_to_int',
  'gsql_ts_to_epoch_seconds',
  'gsql_current_time_epoch',
  'gsql_upper',
  'gsql_lower',
  'gsql_trim',
  'gsql_ltrim',
  'gsql_rtrim',
  'gsql_year',
  'gsql_month',
  'gsql_day',
  'gsql_year_epoch',
  'gsql_month_epoch',
  'gsql_day_epoch',
  'ascii',
  'chr',
  'difference',
  'find_in_set',
  'insert',
  'instr',
  'left',
  'right',
  'lpad',
  'rpad',
  'soundex',
  'space',
  'translate',
];

export const funcsForWhere = [
  ...tokenFuncs,
  'to_float',
  'to_int',
  'concat',
  'token_len',
  'gsql_is_not_empty_string',
  'gsql_token_equal',
  'gsql_token_ignore_case_equal',
  'gsql_is_true',
  'gsql_is_false',
];

const reducerFuncs = ['max', 'min', 'add', 'and', 'or', 'overwrite', 'ignore_if_exists'];

export const funcsForDestClause = [...tokenFuncs, ...reducerFuncs];
