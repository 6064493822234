import { useTheme } from '@/contexts/themeContext';
import { tags as t } from '@lezer/highlight';
// import { xcodeDarkInit, xcodeLightInit } from '@uiw/codemirror-theme-xcode';
import { vscodeDarkInit, vscodeLightInit } from '@uiw/codemirror-theme-vscode';
import { useMemo } from 'react';

export default function useEditorTheme(settings?: { background: string }) {
  const { themeType } = useTheme();
  const theme = useMemo(() => {
    if (themeType === 'dark') {
      return vscodeDarkInit({
        settings: {
          gutterBorder: 'transparent',
          gutterBackground: 'transparent',
          background: settings?.background || 'transparent',
        },
        styles: [
          {
            tag: t.labelName,
            color: '#d16969',
          },
        ],
      });
    }

    return vscodeLightInit({
      settings: {
        gutterBorder: 'transparent',
        gutterBackground: 'transparent',
        background: settings?.background || 'transparent',
      },
      styles: [
        {
          tag: t.labelName,
          color: '#af00db',
        },
      ],
    });
  }, [settings?.background, themeType]);

  return theme;
}
