import './index.css';
import './globals.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from '@/App';
import './suppressLog';

// https://vite.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', (event) => {
  window.location.reload(); // refresh the page
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
