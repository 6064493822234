// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  QueryText = 1,
  String = 2,
  BlockComment = 3,
  LineComment = 4,
  Number = 5,
  Bool = 6,
  ParenL = 7,
  ParenR = 8,
  BraceL = 9,
  BraceR = 10,
  BracketL = 11,
  BracketR = 12,
  Dot = 13,
  Identifier = 14,
  ACCUM = 15,
  ADD = 16,
  ALL = 17,
  ALLOCATE = 18,
  RETURNS = 19,
  ALTER = 20,
  ANY = 21,
  ASC = 22,
  BAG = 23,
  BETWEEN = 24,
  BOOL = 25,
  BY = 26,
  CALL = 27,
  COALESCE = 28,
  COMPRESS = 29,
  COUNT = 30,
  CREATE = 31,
  KAFKA = 32,
  S3 = 33,
  DATETIME_ADD = 34,
  DATETIME_SUB = 35,
  DATETIME_DIFF = 36,
  DATETIME_TO_EPOCH = 37,
  DATETIME_FORMAT = 38,
  DELETE = 39,
  DESC = 40,
  DISTRIBUTED = 41,
  DROP = 42,
  DATETIME = 43,
  DOUBLE = 44,
  EPOCH_TO_DATETIME = 45,
  GSQL_INT_MAX = 46,
  GSQL_UINT_MAX = 47,
  GSQL_INT_MIN = 48,
  EDGE = 49,
  ESCAPE = 50,
  FALSE = 51,
  FILE = 52,
  TO_DATETIME = 53,
  FIXED_BINARY = 54,
  FLOAT = 55,
  FROM = 56,
  GLOBAL = 57,
  GRAPH = 58,
  GROUP = 59,
  HAVING = 60,
  HEADER = 61,
  INDEX = 62,
  INPUT_LINE_FILTER = 63,
  INSERT = 64,
  INT = 65,
  INTERPRET = 66,
  INTO = 67,
  ISEMPTY = 68,
  JOB = 69,
  LIKE = 70,
  LIMIT = 71,
  NOT = 72,
  NOW = 73,
  NULL = 74,
  OPENCYPHER = 75,
  OR = 76,
  ORDER = 77,
  PRIMARY_ID = 78,
  PRIMARY = 79,
  KEY = 80,
  DISCRIMINATOR = 81,
  QUERY = 82,
  REDUCE = 83,
  REPLACE = 84,
  SAMPLE = 85,
  SELECT = 86,
  SELECTVERTEX = 87,
  SET = 88,
  STRING = 89,
  SUM = 90,
  TEMP_TABLE = 91,
  TO = 92,
  TO_CSV = 93,
  WITH = 94,
  REVERSED_EDGE = 95,
  TRIM = 96,
  TRUE = 97,
  TUPLE = 98,
  UINT = 99,
  UPDATE = 100,
  VALUES = 101,
  VERTEX = 102,
  WHERE = 103,
  AVG = 104,
  ABORT = 105,
  ACL = 106,
  API = 107,
  ATTRIBUTE = 108,
  CONCAT = 109,
  DATA = 110,
  DATA_SOURCE = 111,
  DEFINE = 112,
  DIRECTED = 113,
  EMPTY = 114,
  EXPORT = 115,
  FILENAME = 116,
  GET = 117,
  GRANT = 118,
  IMPORT = 119,
  INSTALL = 120,
  JSON = 121,
  LOADING = 122,
  LOCAL = 123,
  LS = 124,
  MAX = 125,
  MIN = 126,
  MINUS = 127,
  OVERWRITE = 128,
  OWNER = 129,
  PAIR = 130,
  PASSWORD = 131,
  PRIVILEGE = 132,
  PUT = 133,
  READ = 134,
  REJECT_LINE_RULE = 135,
  RESUME = 136,
  REVOKE = 137,
  ROLE = 138,
  RUN = 139,
  SCHEMA = 140,
  SCHEMA_CHANGE = 141,
  SECONDARY_ID = 142,
  SECRET = 143,
  SHOW = 144,
  SPLIT = 145,
  STATS = 146,
  STATUS = 147,
  STORE = 148,
  SUBSTR = 149,
  SYNTAX = 150,
  TAG = 151,
  TEMPLATE = 152,
  TOKEN = 153,
  TOKEN_LEN = 154,
  TOKENBANK = 155,
  UNDIRECTED = 156,
  USE = 157,
  USER = 158,
  JSONOBJECT = 159,
  JSONARRAY = 160,
  LOAD = 161,
  USING = 162,
  FOR = 163,
  TAGS = 164,
  AND = 165,
  NUMERIC = 166,
  IN = 167,
  IS = 168,
  TYPEDEF = 169,
  GSQLScript = 170,
  QueryBlock = 171,
  CreateQuery = 172,
  CreateQueryModifiers = 173,
  ParameterList = 174,
  Parameter = 175,
  ParameterType = 176,
  BaseType = 177,
  Constant = 179,
  GraphOption = 180,
  ReturnsOption = 181,
  APIOption = 182,
  SyntaxOption = 183,
  UseGraphStmt = 184,
  RunQueryStmt = 185,
  RunOptions = 186,
  ParameterValueList = 187,
  ParameterConstant = 188,
  ParameterValueJSON = 192,
  ParameterValueJSONPair = 193,
  InstallQueryStmt = 194,
  InstallOptions = 195,
  LoadingJobBlock = 199,
  LoadingJobBodyStmts = 200,
  DefineFilename = 201,
  DefineHeader = 203,
  LoadStmt = 204,
  VertexDestinationClause = 205,
  AttrExpr = 206,
  FunctionCall = 207,
  WhereClause = 208,
  Condition = 209,
  Expression = 210,
  EdgeDestinationClause = 214,
  TempTableClause = 215,
  UsingClause = 216,
  DeleteVertexStmt = 217,
  DeleteEdgeStmt = 218
