import { StyledToast, showToast } from '@/components/styledToasterContainer';
import { Result } from '@/lib/type';
import { ValidateCPRequest, ValidateCPResponse, validateCP } from '@/pages/admin/settings/cloud_provider/api';
import { IFormInput } from '@/pages/admin/settings/cloud_provider/types';
import { getErrorMessage } from '@/utils/utils';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { FormControl } from '@tigergraph/app-ui-lib/form-control';
import { Input } from '@tigergraph/app-ui-lib/input';
import { AxiosError } from 'axios';
import { KIND, SIZE } from 'baseui/button';
import { ReactNode, useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useMutation } from 'react-query';
import { getSubnetId } from '@/pages/admin/settings/cloud_provider/util';
import { Desc, SubSection, Subtitle } from '@/pages/admin/settings/cloud_provider/StyledComponents';
import { Book, Plane } from '@/pages/home/icons';
import ConfirmModal from '@/components/ConfirmModal';
import CopyLink from '@/components/CopyLink';
import { PLACEMENT, Popover } from 'baseui/popover';
import { popoverOverrides } from '@/components/confirmPopover';
import roleArnGif1 from '@/assets/role-arn1.gif';
import roleArnGif2 from '@/assets/role-arn2.gif';

interface CreateRoleProps {
  control: Control<IFormInput, any>;
  errors: FieldErrors<IFormInput>;
  watch: UseFormWatch<IFormInput>;
  setValue: UseFormSetValue<IFormInput>;
  validateForm: UseFormTrigger<IFormInput>;
  cloudPlatform: string;
  setFocus: UseFormSetFocus<IFormInput>;
}

export default function CreateRole({
  control,
  errors,
  watch,
  setValue,
  validateForm,
  cloudPlatform,
  setFocus,
}: CreateRoleProps) {
  const [css, theme] = useStyletron();

  const vpcOwnedByUser = watch('vpcOwnedByUser');
  const awsRoleArn = watch('awsRoleArn');
  const awsExternalId = watch('awsExternalId');
  const vpcId = watch('vpcId');
  const subnetId = watch('subnetId');
  const securityGroupId = watch('securityGroupId');
  const region = watch('region');
  const secureConnection = watch('secureConnection');
  const accountId = watch('trustedAccountId');

  const [editDisabled, setEditDisabled] = useState<Record<string, boolean>>({ secureConnection: false });
  const [showFinishCreateModal, setShowFinishCreateModal] = useState(false);

  useEffect(() => {
    if (vpcOwnedByUser) {
      setValue('secureConnection', true);
      setEditDisabled({ secureConnection: true });
    } else {
      setEditDisabled({ secureConnection: false });
    }
  }, [setValue, vpcOwnedByUser]);

  const [validateErr, setValidateErr] = useState('');
  const { mutate: validate, isLoading: isValidating } = useMutation<
    Result<ValidateCPResponse>,
    AxiosError<Result<ValidateCPResponse>>,
    ValidateCPRequest
  >(
    async (data) => {
      const res = await validateCP(data);
      return res;
    },
    {
      onSuccess() {
        showToast({
          kind: 'positive',
          message: 'Cloud provider validated successfully',
        });
      },
      onError(error) {
        const errorData = error?.response?.data;
        if (errorData?.Result) {
          const { insufficient_quotas, missing_permissions } = errorData.Result;
          let errorMessage = errorData.Message + '\n\n';

          if (insufficient_quotas?.length) {
            errorMessage += 'Insufficient Quotas:\n';
            insufficient_quotas.forEach((quota) => {
              errorMessage += `  • ${quota.service} (${quota.quota_code}): Required ${quota.required}, Available ${quota.available} (Limit: ${quota.limit})\n`;
            });
            errorMessage += '\n';
          }

          if (missing_permissions?.length) {
            errorMessage += 'Missing Permissions:\n';
            missing_permissions.forEach((permission) => {
              errorMessage += `  • ${permission}\n`;
            });
          }

          setValidateErr(errorMessage);
        } else {
          setValidateErr(getErrorMessage(error));
        }
      },
    }
  );

  const onValidate = async () => {
    const formValidation = await validateForm();
    if (!formValidation) {
      return;
    }

    setValidateErr('');
    validate({
      region,
      role_arn: awsRoleArn,
      validate_vpc: vpcOwnedByUser,
      vpc_id: vpcId,
      subnet_id: getSubnetId(subnetId),
      security_group_id: securityGroupId,
      platform: cloudPlatform,
      secure_connection: secureConnection,
      role_external_id: awsExternalId,
      account_id: accountId,
    });
  };

  useEffect(() => {
    if (vpcOwnedByUser) {
      return;
    }

    setValue('vpcId', '');
    setValue('subnetId', '');
    setValue('securityGroupId', '');
  }, [vpcOwnedByUser, setValue]);

  // extract trusted account id from role arn
  // I.E, the ARN is arn:aws:iam::381491969203:role/duhao-cp-role-9203, then the prefilled “Trusted Account Id” is 381491969203
  useEffect(() => {
    const arn = awsRoleArn;
    if (!arn) {
      return;
    }

    const parts = arn.split(':');
    if (parts.length < 6) {
      return;
    }

    setValue('trustedAccountId', parts[4]);
  }, [awsRoleArn, setValue]);

  const randomId = Date.now().toString().slice(0, 10);
  const cloudFormationURL = `https://console.aws.amazon.com/cloudformation/home?region=${region}#/stacks/create/review?stackName=MySampleStack-${randomId}&templateURL=${
    import.meta.env.VITE_BYOC_ROLE_TEMPLATE_URL
  }&param_ExternalId=${awsExternalId}`;

  const [showRoleArnPopover, setShowRoleArnPopover] = useState(false);

  return (
    <>
      <SubSection>Credential Configuration</SubSection>
      <Subtitle>AWS External ID</Subtitle>
      <FormControl>
        <Controller control={control} name="awsExternalId" render={({ field }) => <Input {...field} disabled />} />
      </FormControl>
      <Subtitle>Create Role for Cloud Privoder</Subtitle>
      <Desc>
        Select a method to create a AWS role that we will assume to access resources on your behalf. You can either use
        the CloudFormation script we provide or manually create a role in the AWS console by following the instructions
        in our documentation.
      </Desc>
      <div
        className={css({
          display: 'flex',
          gap: '16px',
          padding: '8px 0 16px',
        })}
      >
        <RoleMethod
          name="Quick Start"
          desc="Automatically create the role using the CloudFormation script we provide"
          url={cloudFormationURL}
          icon={<Plane />}
          onClick={() => {
            setShowFinishCreateModal(true);
          }}
        />
        <RoleMethod
          name="Manual"
          desc="Manually create the role in the AWS console by following the step-by-step instructions"
          url="https://docs.tigergraph.com/cloud/current/start/overview"
          icon={<Book />}
        />
      </div>

      <div
        className={css({
          // borderBottom: `1px solid ${theme.colors['card.border']}`,
          marginBottom: '16px',
        })}
      >
        <Subtitle>AWS Role ARN</Subtitle>
        <Desc>
          <div className={css({ marginBottom: '8px' })}>
            Please follow these{' '}
            <RoleArnPopover isOpen={showRoleArnPopover} onClose={() => setShowRoleArnPopover(false)}>
              <span onClick={() => setShowRoleArnPopover(true)}>steps</span>
            </RoleArnPopover>{' '}
            to locate and copy the created Role ARN from AWS.
            <br />
            Then, paste it into the input box below. You can use the Test button to verify it.
          </div>
        </Desc>
        <FormControl error={errors?.awsRoleArn?.message}>
          <Controller
            control={control}
            name="awsRoleArn"
            rules={{ required: 'Required' }}
            render={({ field }) => <Input {...field} />}
          />
        </FormControl>
      </div>

      {/* <StatefulPanel
        title={<SubSection>Advanced Settings</SubSection>}
        overrides={{
          PanelContainer: {
            style: {
              ...expand({ borderBottom: 'none' }),
            },
          },
          Content: {
            style: {
              ...expand({
                padding: '0',
                borderRadius: '4px',
                border: `1px solid ${theme.colors['border.tertiary']}`,
              }),
            },
          },
          Header: {
            style: {
              ...expand({ padding: '0 0 8px 0', backgroundColor: 'transparent' }),
            },
          },
          ToggleIcon: ({ $expanded }) =>
            $expanded ? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowDown size={20} />,
        }}
      >
        <div
          className={css({
            padding: '0px 16px',
            borderBottom: `1px solid ${theme.colors['border.tertiary']}`,
          })}
        >
          <FormTitleContainer>
            <TooltipLabel label="Secure Connection" tooltip="" />
            <Controller
              control={control}
              name="secureConnection"
              render={({ field: { value, onChange, ref: _, ...field } }) => (
                <Checkbox
                  checked={value}
                  labelPlacement="left"
                  onChange={() => {
                    onChange(!value);
                  }}
                  {...field}
                  disabled={editDisabled.secureConnection}
                  checkmarkType={STYLE_TYPE.toggle_round}
                />
              )}
            />
          </FormTitleContainer>
          {secureConnection && (
            <FormControl label="Trusted Account ID" error={errors?.trustedAccountId?.message}>
              <Controller
                control={control}
                rules={{ required: 'Required' }}
                name="trustedAccountId"
                render={({ field }) => <Input id="trustedAccountId" {...field} />}
              />
            </FormControl>
          )}
        </div>
        <div className={css({ padding: '0px 16px' })}>
          <FormTitleContainer>
            <TooltipLabel label="User Owned VPC" tooltip="" />
            <Controller
              control={control}
              name="vpcOwnedByUser"
              render={({ field: { value, onChange, ref: _, ...field } }) => (
                <Checkbox
                  checked={value}
                  labelPlacement="left"
                  onChange={() => {
                    onChange(!value);
                  }}
                  {...field}
                  checkmarkType={STYLE_TYPE.toggle_round}
                />
              )}
            />
          </FormTitleContainer>
          {vpcOwnedByUser && (
            <>
              <FormControl label="VPC ID" error={errors?.vpcId?.message}>
                <Controller
                  rules={{ required: 'Required' }}
                  control={control}
                  name="vpcId"
                  render={({ field }) => <Input id="vpcId" {...field} />}
                />
              </FormControl>
              <FormControl label="Subnet ID" error={errors?.subnetId?.message}>
                <Controller
                  control={control}
                  rules={{ required: 'Required' }}
                  name="subnetId"
                  render={({ field }) => <Input id="subnetId" {...field} />}
                />
              </FormControl>
              <div className={css({ color: '#656565', ...theme.typography.Label, marginTop: '-16px' })}>
                You can specify multiple subnet id by comma-separating them.
              </div>
              <FormControl label="Security Group ID" error={errors?.securityGroupId?.message}>
                <Controller
                  control={control}
                  rules={{ required: 'Required' }}
                  name="securityGroupId"
                  render={({ field }) => <Input id="securityGroupId" {...field} />}
                />
              </FormControl>
            </>
          )}
        </div>
      </StatefulPanel> */}

      <div className={css({ margin: '8px 0 16px' })}>
        <Button
          kind={KIND.secondary}
          size={SIZE.compact}
          overrides={{ BaseButton: { style: { fontWeight: 500 } } }}
          onClick={(evt) => {
            evt.preventDefault();
            onValidate();
          }}
          isLoading={isValidating}
        >
          Test
        </Button>
      </div>
      {!!validateErr && (
        <StyledToast hideBorder={true} kind="negative" title="Validation Failed" message={validateErr} />
      )}
      <FinishCreationModal
        isOpen={showFinishCreateModal}
        cloudFormationURL={cloudFormationURL}
        onCancel={() => {
          setShowFinishCreateModal(false);
        }}
        onFinish={() => {
          setShowFinishCreateModal(false);
          setShowRoleArnPopover(true);
        }}
      />
    </>
  );
}

interface RoleMethodProps {
  icon: ReactNode;
  name: string;
  desc: string;
  url: string;
  onClick?: () => void;
}

function RoleMethod({ icon, name, desc, url, onClick }: RoleMethodProps) {
  const [css, theme] = useStyletron();

  return (
    <a
      href={url}
      target="_blank"
      onClick={onClick}
      className={css({
        flex: '1',
        display: 'flex',
        height: '108px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '2px',
        border: `1px solid ${theme.colors['card.border']}`,
        gap: '4px',
      })}
      rel="noreferrer"
    >
      <div className={css({ marginBottom: '8px' })}>{icon}</div>
      <div
        className={css({
          ...theme.typography.Label,
          color: theme.colors['card.text.primary'],
          fontWeight: 700,
          lineHeight: '16px',
        })}
      >
        {name}
      </div>
      <Desc $style={{ margin: '0 32px', textAlign: 'center' }}>{desc}</Desc>
    </a>
  );
}

interface FinishCreationModalProps {
  isOpen: boolean;
  cloudFormationURL: string;
  onCancel: () => void;
  onFinish: () => void;
}

function FinishCreationModal({ isOpen, cloudFormationURL, onCancel, onFinish }: FinishCreationModalProps) {
  const [css, theme] = useStyletron();

  const body = (
    <div>
      <div>
        Creating a stack in AWS CloudFormation typically takes a few minutes. Please stay on this page until the stack
        creation process is complete. If you navigate away, the deployment may encounter unexpected issues.
      </div>
      <div>
        If the stack creation fails, verify that your AWS account has the required permissions. Once the stack is
        successfully created, click Finish to proceed.
      </div>
      <div className={css({ position: 'absolute', bottom: '24px' })}>
        <CopyLink link={cloudFormationURL} />
      </div>
    </div>
  );

  return (
    <ConfirmModal
      header="Finish the AWS Cloudformation creation"
      body={body}
      open={isOpen}
      onConfirm={onFinish}
      onCancel={onCancel}
      confirmLabel="Finish"
      overrides={{
        Dialog: {
          style: {
            width: '640px',
          },
        },
      }}
    />
  );
}
function RoleArnPopover({ children, isOpen, onClose }: { children: ReactNode; isOpen: boolean; onClose: () => void }) {
  const [css, theme] = useStyletron();

  return (
    <Popover
      showArrow={true}
      isOpen={isOpen}
      onClickOutside={() => onClose()}
      onEsc={() => onClose()}
      content={() => <RoleArnPopoverContent />}
      overrides={popoverOverrides}
      placement={PLACEMENT.bottom}
      ignoreBoundary={true}
    >
      <span className={css({ color: theme.colors['linkText'], cursor: 'pointer' })}>{children}</span>
    </Popover>
  );
}

function RoleArnPopoverContent() {
  const [css, theme] = useStyletron();

  const imageURLs = [roleArnGif1, roleArnGif2];
  const [idx, setIdx] = useState(0);

  return (
    <div className={css({ display: 'flex', flexDirection: 'column', gap: '12px' })}>
      <div
        className={css({ ...theme.typography.HeadingXSmall, fontSize: '16px', lineHeight: '16px', fontWeight: 600 })}
      >
        How to get AWS role ARN
      </div>
      <div>Once you have created an IAM Role, paste the IAM Role ARN here.</div>
      <div className={css({ height: '210px', overflow: 'hidden' })}>
        <img src={imageURLs[idx]} alt="" width={436} />
      </div>
      <div className={css({ display: 'flex', justifyContent: 'space-between', alignItems: 'center' })}>
        <div className={css({ display: 'flex', gap: '8px' })}>
          {imageURLs.map((_, _idx) => (
            <div
              key={_idx}
              className={css({
                width: '5px',
                height: '5px',
                borderRadius: '50%',
                backgroundColor:
                  idx !== _idx
                    ? theme.colors['background.accent.neutral.subtler']
                    : theme.colors['background.selected.bold'],
              })}
            ></div>
          ))}
        </div>
        <Button kind={KIND.secondary} onClick={() => setIdx((idx + 1) % imageURLs.length)}>
          {idx === 0 ? 'Next' : 'Prev'}
        </Button>
      </div>
    </div>
  );
}
