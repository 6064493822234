import { containerTypes } from '@/pages/editor/GSQL/queryKw';
import { Text } from '@codemirror/state';
import { SyntaxNode } from '@lezer/common';
import { InputStream } from '@lezer/lr';

export const enum Ch {
  Newline = 10,
  Space = 32,
  DoubleQuote = 34,
  Hash = 35,
  Dollar = 36,
  SingleQuote = 39,
  ParenL = 40,
  ParenR = 41,
  Star = 42,
  Plus = 43,
  Comma = 44,
  Dash = 45,
  Dot = 46,
  Slash = 47,
  Colon = 58,
  Semi = 59,
  Question = 63,
  At = 64,
  BracketL = 91,
  BracketR = 93,
  Backslash = 92,
  Underscore = 95,
  Backtick = 96,
  BraceL = 123,
  BraceR = 125,

  A = 65,
  a = 97,
  B = 66,
  b = 98,
  E = 69,
  e = 101,
  F = 70,
  f = 102,
  N = 78,
  n = 110,
  Q = 81,
  q = 113,
  X = 88,
  x = 120,
  Z = 90,
  z = 122,

  _0 = 48,
  _1 = 49,
  _9 = 57,
}

export function isAlpha(ch: number) {
  return (ch >= Ch.A && ch <= Ch.Z) || (ch >= Ch.a && ch <= Ch.z) || (ch >= Ch._0 && ch <= Ch._9);
}

export function isHexDigit(ch: number) {
  return (ch >= Ch._0 && ch <= Ch._9) || (ch >= Ch.a && ch <= Ch.f) || (ch >= Ch.A && ch <= Ch.F);
}

export function readLiteral(input: InputStream, endQuote: number, backslashEscapes: boolean) {
  for (let escaped = false; ; ) {
    if (input.next < 0) return;
    if (input.next == endQuote && !escaped) {
      input.advance();
      return;
    }
    escaped = backslashEscapes && !escaped && input.next == Ch.Backslash;
    input.advance();
  }
}

export function readTripleQuotedString(input: InputStream, endQuote: number) {
  input.advance();
  input.advance();
  let quoteCnt = 0;
  for (;;) {
    const next = input.next;
    input.advance();
    if (next < 0) break;
    if (next == endQuote) {
      quoteCnt++;
      if (quoteCnt == 3) {
        break;
      }
    } else {
      quoteCnt = 0;
    }
  }
}

export function readWord(input: InputStream, result?: string) {
  for (;;) {
    if (input.next != Ch.Underscore && !isAlpha(input.next)) break;
    if (result !== undefined) result += String.fromCharCode(input.next);
    input.advance();
  }
  return result;
}

export function readNumber(input: InputStream, sawDot: boolean) {
  for (;;) {
    if (input.next == Ch.Dot) {
      if (sawDot) break;
      sawDot = true;
    } else if (input.next < Ch._0 || input.next > Ch._9) {
      break;
    }
    input.advance();
  }
  if (input.next == Ch.E || input.next == Ch.e) {
    input.advance();
    if ((input as any).next == Ch.Plus || (input as any).next == Ch.Dash) input.advance();
    while (input.next >= Ch._0 && input.next <= Ch._9) input.advance();
  }
}

export function eol(input: InputStream) {
  while (!(input.next < 0 || input.next == Ch.Newline)) input.advance();
}

export function inString(ch: number, str: string) {
  for (let i = 0; i < str.length; i++) if (str.charCodeAt(i) == ch) return true;
  return false;
}

export function isContainerType(type: string) {
  return containerTypes.includes(type.split('<')[0].toUpperCase());
}

export function findParent(at: SyntaxNode, names: string[]) {
  for (let parent = at.parent; parent; parent = parent.parent) {
    if (names.includes(parent.name)) return parent;
  }
  return null;
}

export function getQueryGraphName(doc: Text, at: SyntaxNode) {
  let stmt: SyntaxNode | null = null;
  for (let parent: SyntaxNode | null = at; !stmt; parent = parent.parent) {
    if (!parent) return '';
    if (parent.name == 'CreateQuery') stmt = parent;
  }

  const graphNode = stmt.getChild('GraphOption')?.getChild('Identifier');
  if (graphNode) {
    return doc.sliceString(graphNode.from, graphNode.to);
  }

  // search upwards for a QueryBlock
  let queryBlock: SyntaxNode | null = stmt;
  for (; queryBlock; queryBlock = queryBlock.parent) {
    if (queryBlock.name === 'QueryBlock') break;
  }
  if (!queryBlock) return '';

  // search prev siblings for a UseGraphStmt
  for (stmt = queryBlock; stmt; stmt = stmt.prevSibling) {
    if (stmt.name === 'UseGraphStmt') {
      const graphNode = stmt.getChild('Identifier');
      return graphNode ? doc.sliceString(graphNode.from, graphNode.to) : '';
    }
  }

  return '';
}

export const findCypherNodeUpwards = (at: SyntaxNode) => {
  return findParent(at, ['Document']);
};

export const getQueryParams = (doc: Text, queryStmtNode: SyntaxNode) => {
  const parameterList = queryStmtNode.getChild('ParameterList');
  const result = new Map<string, string>();
  if (parameterList) {
    for (const param of parameterList.getChildren('Parameter')) {
      const paramTypeNode = param.getChild('ParameterType');
      const paramName = paramTypeNode?.nextSibling;
      if (paramName?.name === 'Identifier') {
        const varName = doc.sliceString(paramName.from, paramName.to);
        result.set(varName, doc.sliceString(paramTypeNode!.from, paramTypeNode!.to));
      }
    }
  }

  return result;
};
