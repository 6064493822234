import { FormControl } from '@/components/FormControl';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Input } from '@tigergraph/app-ui-lib/input';
import { Select } from '@tigergraph/app-ui-lib/select';
import { useForm, Controller } from 'react-hook-form';
import { styled } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';

import { Form, FormContentContainer, Desc, FormControllerContainer, FormContent } from './StyledComponent';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CreateWorkGroupRequest } from '@/pages/workgroup/type';
import { LoadingIndicator } from '@/components/loading-indicator';
import { useCloudProviders, useQueryGetGroups } from '@/pages/workgroup/hook';
import { NameRules, generateWorkgroupName } from '@/pages/workgroup/form/name';
import { ErrorDisplay } from '@/components/error';
import { useOrgContext } from '@/contexts/orgContext';
import { CloudProviderT } from '@/pages/admin/settings/cloud_provider/types';
import { isCPDisabled } from '@/pages/admin/settings/cloud_provider/util';
import CloudProviderCategory from '@/pages/admin/settings/cloud_provider/CloudProviderCategory';
import { getRegionName } from '@/pages/admin/settings/cloud_provider/region';

export default function WorkGroupForm() {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();

  const groupsQuery = useQueryGetGroups();
  const initNameref = useRef<boolean>(false);

  const [selectedCP, setSelectedCP] = useState<CloudProviderT | null>(null);
  // will display stale cloud providers but not allow to select them
  const filterFn = useCallback((cp: CloudProviderT) => !isCPDisabled(cp) || cp.status === 'stale', []);
  const { cps, isCpLoading } = useCloudProviders(filterFn);

  useEffect(() => {
    if (cps.length && !selectedCP && !isCPDisabled(cps[0])) {
      setSelectedCP(cps[0]);
    }
  }, [selectedCP, setSelectedCP, cps]);

  const regionOptions: { id: string; label: string }[] = useMemo(() => {
    if (!selectedCP) {
      return [];
    }

    if (selectedCP.type !== 'public') {
      return [{ id: selectedCP.id, label: getRegionName(selectedCP) }];
    }

    const samePlatformCps = cps.filter(
      (cp) => cp.platform === selectedCP.platform && cp.type === 'public' && !isCPDisabled(cp)
    );
    return samePlatformCps.map((p) => {
      const hasDuplicateRegion = samePlatformCps.find((_p) => _p.region === p.region && _p.id !== p.id);
      return {
        id: p.id,
        label: `${getRegionName(p)}${hasDuplicateRegion ? ` (${p.name})` : ''}`,
      };
    });
  }, [selectedCP, cps]);

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setFocus,
    setValue,
    watch,
  } = useForm<CreateWorkGroupRequest>({
    mode: 'onBlur',
    // default name from url params
    defaultValues: {
      name: searchParams.get('name') || '',
      region: searchParams.get('region') || '',
    },
  });

  const name = watch('name');
  const region = watch('region');

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  useEffect(() => {
    if (initNameref.current) {
      return;
    }
    if (groupsQuery.data?.Result) {
      if (!name) {
        setValue('name', generateWorkgroupName(groupsQuery.data.Result));
      }
      initNameref.current = true;
    }
  }, [groupsQuery.data?.Result, setValue, name]);

  const { currentOrg, userInfo } = useOrgContext();

  // sync state to url search params
  useEffect(() => {
    if (searchParams.get('name') !== name || searchParams.get('region') !== region) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('name', name);
      newSearchParams.set('region', region);
      setSearchParams(newSearchParams, {
        replace: true,
      });
    }
  }, [searchParams, setSearchParams, name, region]);

  // set a default region
  useEffect(() => {
    if (!regionOptions.length) {
      return;
    }

    if (region && regionOptions.find((r) => r.id === region)) {
      return;
    }

    // if region is not set, and there is a public cp in us-east-1, set it as default
    const usEast1 = regionOptions.find((r) => !!cps.find((cp) => cp.id === r.id && cp.region === 'us-east-1'));
    setValue('region', usEast1?.id || regionOptions[0].id);
  }, [regionOptions, region, setValue, cps]);

  if (groupsQuery.isLoading || isCpLoading) {
    return <LoadingIndicator />;
  }

  if (groupsQuery.isError) {
    return <ErrorDisplay error={groupsQuery.error} label="Error:" />;
  }

  const disabledCP = selectedCP?.id === 'azure' || selectedCP?.id === 'gcp';
  const url = `mailto:support@tigergraph.com?subject=TigerGraph Savanna Support - Request New Region - ${userInfo.email} - ${currentOrg.org_id} - ${currentOrg.org_name}&body=Hi Support Team,%0A%0A%0A%0A%0A%0ARegards,%0A${userInfo.email}`;

  return (
    <Form
      onSubmit={handleSubmit((data) => {
        let cp = selectedCP!;
        if (cp.type === 'public') {
          cp = cps.find((cpOp) => cpOp.id === data.region)!;
        }

        let queryString = '';
        if (searchParams.has('solution')) {
          const params = new URLSearchParams();
          params.set('solution', searchParams.get('solution')!);
          queryString = `?${params.toString()}`;
        }

        navigate(`spaces/config${queryString}`, {
          state: {
            createGroupRequest: {
              ...data,
              platform: cp.platform,
              region: cp.region,
              cloud_provider_id: cp.id,
            },
          },
        });
      })}
    >
      <FormContentContainer>
        <FormContent>
          <h1 className={css({ ...theme.typography.HeadingMedium })}>Create Workgroup</h1>
          <p className={css({ ...theme.typography.Body2, color: theme.colors.contentSecondary, marginBottom: '8px' })}>
            A workgroup is a group of databases and their workspaces which you can manage together as a unit.
          </p>
          <FormControllerContainer>
            <div>
              <FormControl label="Workgroup Name" error={errors?.name?.message}>
                <Controller
                  rules={{
                    ...NameRules,
                    validate(value) {
                      const groupNames = groupsQuery.data?.Result?.map((i) => i.name) || [];
                      if (groupNames?.includes(value)) {
                        return `'${value}' is exist, please use another name`;
                      }
                    },
                  }}
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <Input
                      placeholder="Enter workgroup name"
                      {...field}
                      error={!!errors?.name}
                      autoComplete="off"
                      size="large"
                    />
                  )}
                />
              </FormControl>
              <Desc>Enter a descriptive name for your workgroup.</Desc>
            </div>
            <div>
              <FormControl
                label="Cloud Provider"
                caption={
                  disabledCP && (
                    <>
                      TigerGraph Savanna does not support GCP and Azure yet, please use
                      <a
                        href="https://tgcloud.io/"
                        target="_blank"
                        rel="noreferrer"
                        className={css({
                          color: theme.colors['text.link'],
                        })}
                      >
                        TigerGraph Cloud Classic
                      </a>{' '}
                      to create v3.X solutions.
                    </>
                  )
                }
              >
                <div className={css({ display: 'flex', gap: '12px' })}>
                  {cps.map((cp, idx) => {
                    if (
                      cps
                        .slice(0, idx)
                        .find((_cp) => _cp.type === 'public' && _cp.type === cp.type && _cp.platform === cp.platform)
                    ) {
                      return null;
                    }

                    return (
                      <CloudProviderCategory
                        key={cp.id}
                        selected={selectedCP?.id == cp.id}
                        disabled={isCPDisabled(cp)}
                        onSelect={() => setSelectedCP(cp)}
                        {...cp}
                      />
                    );
                  })}
                </div>
              </FormControl>
            </div>
            {!disabledCP && (
              <div>
                <FormControl label="Workgroup Region">
                  <Controller
                    control={control}
                    name="region"
                    render={({ field: { value, onChange, ref, ...field } }) => (
                      <Select
                        disabled={!(selectedCP?.type === 'public')}
                        options={regionOptions}
                        value={[{ id: value }]}
                        onChange={(params) => onChange(params.value[0].id)}
                        clearable={false}
                        inputRef={ref}
                        size="large"
                        {...field}
                        overrides={{
                          Dropdown: {
                            style: {
                              maxHeight: '300px',
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
                <Desc>
                  Can&#39;t find your region? Please{' '}
                  <a className={css({ color: theme.colors.linkText })} href={url} target="_blank" rel="noreferrer">
                    contact us
                  </a>{' '}
                  if you want to try TigerGraph on another region.
                </Desc>
              </div>
            )}
          </FormControllerContainer>
        </FormContent>
      </FormContentContainer>
      <BottomContainer>
        <Button
          type="button"
          size="large"
          onClick={() => {
            navigate(-1);
          }}
          kind="secondary"
        >
          Cancel
        </Button>
        <Button size="large" disabled={!selectedCP || !region || disabledCP}>
          Next
        </Button>
      </BottomContainer>
    </Form>
  );
}

const BottomContainer = styled('div', ({ $theme }) => ({
  borderTop: `1px solid ${$theme.colors.divider}`,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
  padding: '16px 32px',
  backgroundColor: $theme.colors['background.alternative'],
}));
