import { StyledForm, StyledFormAction, StyledFormBody } from '@/pages/workgroup/form/setting/styled';
import { WorkspaceT } from '@/pages/workgroup/type';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useForm, Controller } from 'react-hook-form';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { FormControl } from '@/components/FormControl';
import { Input } from '@tigergraph/app-ui-lib/input';
import { FormControlOverrides } from 'baseui/form-control';
import { CustomTheme, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ConfigType } from '@/pages/workgroup/form/setting/api';
import {
  useMutationRestartService,
  useMutationUpdateConfig,
  useQueryConfig,
} from '@/pages/workgroup/form/setting/hook';
import { LoadingIndicator } from '@/components/loading-indicator';
import { ErrorDisplay } from '@/components/error';
import { showToast } from '@tigergraph/app-ui-lib/styledToasterContainer';
import { getErrorMessage } from '@/utils/utils';
import { useState } from 'react';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { isEqual } from 'lodash-es';
import { Desc } from '@/pages/workgroup/form/StyledComponent';
import { HeadingMenuSelected } from '@tigergraph/app-ui-lib/typography';
import TooltipLabel from '@/components/TooltipLabel';

const githubAccessTokenKey = 'GSQL.GithubUserAcessToken';

const Configs = [
  {
    category: 'System',
    subs: {
      Query: [
        {
          key: 'RESTPP.Factory.DefaultQueryTimeoutSec',
          label: 'Default Query Timeout (Seconds)',
          tooltips:
            'The default timeout (s) for all query request. You can set "GSQL-TIMEOUT" in your request header or run "SET query_timeout=xxx" in your query to override this value.',
          servicesToRestart: ['restpp', 'gui', 'nginx'],
        },
        {
          key: 'GPE.QueryLocalMemLimitMB',
          label: 'Maximum Memory Per Query Per Node (MB)',
          tooltips:
            'Maximum limit of how much memory a query is allowed to use on any single node in a cluster. By default, there is no limit to the amount of memory a query is allowed to consume until the overall free memory reaches a critical level. You can set "GSQL-QueryLocalMemLimitMB" in your request header to override this value.',
          caption: '0 means no limit is applied.',
          servicesToRestart: ['gpe'],
        },
        {
          key: 'GSQL.QueryResponseMaxSizeByte',
          label: 'Maximum GSQL Response Size (Bytes)',
          tooltips:
            'The maximum GSQL query response size in byte. You can set RESPONSE-LIMIT in your request header to override this value.',
          servicesToRestart: ['gsql'],
        },
        {
          key: 'Nginx.ClientMaxBodySize',
          label: 'Maximum Request Body Size (MB)',
          tooltips: 'The maximum size for a request body, including the payload file',
          servicesToRestart: ['gui', 'gsql', 'nginx'],
        },
        // hide this config for end user
        // {
        //   key: 'GUI.RESTPPResponseMaxSizeBytes',
        //   label: 'Maximum API Response Size (Bytes)',
        //   tooltips: 'The maximum REST++ API response size in byte.',
        //   servicesToRestart: ['gui'],
        // },
      ],
      // Timeout: [
      //   {
      //     key: 'GUI.HTTPRequest.TimeoutSec',
      //     label: 'HTTP Request Timeout (Seconds)',
      //     servicesToRestart: ['gui'],
      //   },
      // ],
      'Workload Management': [
        {
          key: 'RESTPP.WorkLoadManager.MaxHeavyBuiltinQueries',
          label: 'Maximum Concurrent Running "Heavy" Built-in Queries',
          tooltips:
            'The maximum number of concurrent “heavy“ built-in queries (kstep_expansion, searchvertex, allpaths, shortestpath) on a restpp server',
          servicesToRestart: ['restpp', 'gui', 'nginx'],
        },
        {
          key: 'RESTPP.WorkLoadManager.MaxConcurrentQueries',
          label: 'Maximum Concurrent Running Queries',
          tooltips: 'The maximum number of concurrent queries allowed to run, excluding heavy queries',
          servicesToRestart: ['restpp', 'gui', 'nginx'],
        },
        {
          key: 'RESTPP.WorkLoadManager.MaxDelayQueueSize',
          label: 'Maximum Delay Queue Size',
          tooltips:
            'The maximum number of queries that can be delayed in the queue. If the queue is full, the new query will be rejected.',
          servicesToRestart: ['restpp', 'gui', 'nginx'],
        },
      ],
      Loading: [
        {
          key: 'KafkaLoader.ReplicaNumber',
          label: 'Maximum Number of Concurrent Kafka Loading Jobs Per Node',
          tooltips: 'The number of replicas of Fileloader per node',
          servicesToRestart: ['restpp', 'gpe', 'gse', 'ctrl'],
        },
        {
          key: 'KafkaConnect.AllowedTaskPerCPU',
          label: 'Maximum Allowed Task Numbers Per CPU',
          tooltips:
            'The maximum allowed task numbers per CPU for the overall running connectors. E.g. in an 8-core machine and 1.5 AllowedTaskPerCPU, the system allows a total of 12 concurrent running tasks at maximum for all the running connectors. New connector creation will be denied if the creation results in exceeding the limit.',
          servicesToRestart: ['restpp', 'gpe', 'gse', 'ctrl'],
        },
      ],
    },
  },
  {
    category: 'Advanced',
    subs: {
      UDF: [
        {
          key: 'GSQL.GithubUrl',
          label: 'Github URL For UDF',
          tooltips: 'The url that is used for github enterprise, e.g. https://api.github.com',
          placeHolder: 'https://api.github.com',
          type: 'string',
          optional: true,
          servicesToRestart: ['gsql'],
        },
        {
          key: 'GSQL.GithubRepository',
          label: 'Github Repository For UDF',
          tooltips: 'The repository name for fetch UDF, e.g. tigergraph/ecosys',
          placeHolder: 'tigergraph/ecosys',
          type: 'string',
          optional: true,
          servicesToRestart: ['gsql'],
        },
        {
          key: 'GSQL.GithubBranch',
          label: 'Repository Branch For UDF',
          tooltips: 'The working branch in provided repository. Will use `master` as the default branch.',
          placeHolder: 'master',
          type: 'string',
          optional: true,
          servicesToRestart: ['gsql'],
        },
        {
          key: 'GSQL.GithubPath',
          label: 'Repository Path For UDF',
          tooltips:
            'The path to the directory in the github that has TokenBank.cpp, ExprFunctions.hpp, ExprUtil.hpp, e.g. sample_code/src.',
          placeHolder: 'UDF',
          type: 'string',
          optional: true,
          servicesToRestart: ['gsql'],
        },
        {
          key: 'GSQL.GithubUserAcessToken',
          label: 'Github User Access Token For UDF',
          tooltips: 'The credential for github. Set it to `anonymous` for public access, or empty to not use github',
          skipFetch: true,
          type: 'password',
          optional: true,
          servicesToRestart: ['gsql'],
        },
      ],
      S3: [
        {
          key: 'GPE.QueryOutputS3AWSAccessKeyID',
          label: 'S3 AWS Access Key ID For Query Output',
          tooltips: 'The AWS Access Key ID for the S3 bucket where the query output is stored.',
          type: 'password',
          servicesToRestart: ['gpe'],
        },
        {
          key: 'GPE.QueryOutputS3AWSSecretAccessKey',
          label: 'S3 AWS Secret Access Key For Query Output',
          tooltips: 'The AWS Secret Access Key for the S3 bucket where the query output is stored.',
          type: 'password',
          servicesToRestart: ['gpe'],
        },
      ],
    },
  },
];

export default function TigergraphForm({ workspace, onClose }: { workspace: WorkspaceT; onClose: () => void }) {
  const [css, theme] = useStyletron();

  const configs = Configs;

  let keys: string[] = ['GUI.RESTPPResponseMaxSizeBytes'];
  for (let config of configs) {
    const subs = config.subs;
    const subKeys = Object.keys(subs) as Array<keyof typeof subs>;
    for (let subKey of subKeys) {
      const sub = subs[subKey];
      if (!sub) {
        continue;
      }
      for (let i = 0; i < sub.length; i++) {
        const k = sub[i];
        if ('skipFetch' in k && k.skipFetch) {
          continue;
        }
        keys.push(k.key);
      }
    }
  }

  const { isFetching, isError, error } = useQueryConfig(workspace.nginx_host, keys, {
    // set form default value
    onSuccess(data) {
      if (data.results) {
        const defaultValues: Record<string, string | number> = {};
        for (let key of Object.keys(data.results)) {
          defaultValues[escapeKey(key)] = `${data.results[key]}`;
        }

        if (data.results['GSQL.GithubRepository']) {
          defaultValues[escapeKey(githubAccessTokenKey)] = '<masked>';
        } else {
          defaultValues[escapeKey(githubAccessTokenKey)] = '';
        }
        reset(defaultValues);
      }
    },
  });

  const updateConfigMutation = useMutationUpdateConfig();

  const restartServiceMutation = useMutationRestartService();
  const [showRestartDialog, setShowRestartDialog] = useState(false);

  const form = useForm<ConfigType>({
    defaultValues: {},
  });

  const {
    handleSubmit,
    control,
    formState: { errors, defaultValues },
    reset,
    watch,
  } = form;

  if (isFetching) {
    return <LoadingIndicator />;
  }
  if (isError) {
    return <ErrorDisplay error={error} label="Failed to fetch TigerGraph configuration." />;
  }

  const currentValues = watch();
  const isSame = isEqual(defaultValues, currentValues);

  const config: ConfigType = {};

  const serviceToRestart = new Set<string>();
  if (defaultValues) {
    for (let key of Object.keys(defaultValues)) {
      const originalValue = defaultValues[key];
      const currentValue = currentValues[key];
      if (`${currentValue}` !== `${originalValue}`) {
        config[unEscapeKey(key)] = currentValue;

        // sync GUI.RESTPPResponseMaxSizeBytes with GSQL.QueryResponseMaxSizeByte
        if (unEscapeKey(key) === 'GSQL.QueryResponseMaxSizeByte') {
          config['GUI.RESTPPResponseMaxSizeBytes'] = currentValue;
          serviceToRestart.add('gui');
        }

        for (let config of configs) {
          const subs = config.subs;
          const subKeys = Object.keys(subs) as Array<keyof typeof subs>;

          for (let subKey of subKeys) {
            const sub = subs[subKey];
            if (!sub) {
              continue;
            }
            for (let i = 0; i < sub.length; i++) {
              const k = sub[i];
              if (k.key === unEscapeKey(key)) {
                for (let service of k.servicesToRestart) {
                  serviceToRestart.add(service);
                }
              }
            }
          }
        }
      }
    }
  }

  const onUpdate = () => {
    updateConfigMutation.mutate(
      {
        nginx_host: workspace.nginx_host,
        config: config,
      },
      {
        onSuccess() {
          setShowRestartDialog(true);
        },
        onError(error) {
          showToast({
            kind: 'negative',
            message: `Failed to apply the new configurations: ${getErrorMessage(error)}.`,
          });
        },
      }
    );
  };

  const renderSub = (config: (typeof Configs)[number]) => {
    const subs = config.subs;
    const subKeys = Object.keys(subs) as Array<keyof typeof subs>;
    return (
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        })}
      >
        {subKeys.map((subKey) => {
          const sub = subs[subKey];
          if (!sub) {
            return null;
          }
          return (
            <div
              key={subKey}
              className={css({
                padding: '16px',
                borderRadius: '2px',
                border: `1px solid ${theme.colors['border.tertiary']}`,
                backgroundColor: theme.colors['background.disabled'],
              })}
            >
              <HeadingMenuSelected
                className={css({
                  color: theme.colors['text.primary'],
                  fontWeight: 600,
                })}
              >
                {subKey}
              </HeadingMenuSelected>
              <div
                className={css({
                  isolation: 'isolate',
                  marginTop: '12px',
                  height: '1px',
                  backgroundColor: `${theme.colors['border.tertiary']}`,
                  marginBottom: '20px',
                })}
              />
              {sub.map((key) => {
                return (
                  <FormControl
                    label={<TooltipLabel label={key.label} tooltip={key.tooltips ?? ''} />}
                    caption={'caption' in key ? key.caption : undefined}
                    error={errors?.[escapeKey(key.key)]?.message}
                    key={escapeKey(key.key)}
                    overrides={formControlOverrides}
                  >
                    <Controller
                      control={control}
                      name={escapeKey(key.key)}
                      rules={
                        'optional' in key && key.optional
                          ? {}
                          : {
                              required: 'required',
                            }
                      }
                      render={({ field }) => (
                        <Input
                          {...field}
                          error={!!errors?.[escapeKey(key.key)]}
                          type={'type' in key ? (key.type as string) : 'number'}
                          placeholder={'placeHolder' in key ? key.placeHolder : undefined}
                          aria-label={escapeKey(key.key)}
                        />
                      )}
                    />
                  </FormControl>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <Desc
        $style={{
          marginTop: '6px',
          marginBottom: '12px',
        }}
      >
        Configure the TigerGraph services. For new configurations to take effect, some of the services might need to be
        restarted.
      </Desc>
      <StyledForm
        className={css({
          flex: 1,
          height: 'auto',
        })}
      >
        <StyledFormBody>
          <Accordion type="single" collapsible={true}>
            {configs.map((config) => (
              <AccordionItem value={config.category} key={config.category}>
                <AccordionTrigger>{config.category}</AccordionTrigger>
                <AccordionContent>{renderSub(config)}</AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </StyledFormBody>
        <StyledFormAction>
          <Button type="button" kind="secondary" size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="button"
            size="large"
            onClick={handleSubmit(async () => {
              onUpdate();
            })}
            isLoading={updateConfigMutation.isLoading}
            disabled={isSame || updateConfigMutation.isLoading}
          >
            Save
          </Button>
        </StyledFormAction>
      </StyledForm>
      <Modal onClose={() => setShowRestartDialog(false)} isOpen={showRestartDialog}>
        <ModalHeader>Restart the server</ModalHeader>
        <ModalBody>
          For new configurations to take effect,{' '}
          {Array.from(serviceToRestart)
            .map((s) => s.toUpperCase())
            .join(', ')}{' '}
          server needs to be restarted.
        </ModalBody>
        <ModalFooter>
          <ModalButton kind="secondary" onClick={() => setShowRestartDialog(false)}>
            Cancel
          </ModalButton>
          <ModalButton
            isLoading={restartServiceMutation.isLoading}
            disabled={restartServiceMutation.isLoading}
            onClick={() => {
              restartServiceMutation.mutate(
                {
                  nginx_host: workspace.nginx_host,
                  services: Array.from(serviceToRestart),
                },
                {
                  onSuccess() {
                    reset(currentValues);
                    setShowRestartDialog(false);
                    showToast({
                      kind: 'positive',
                      message:
                        'Please note that the service will undergo a complete restart to become online. The duration of this process depends on the data size.',
                      autoHideDuration: 10 * 1000,
                    });
                  },
                  onError(error) {
                    showToast({
                      kind: 'negative',
                      message: `Failed to restart the services: ${getErrorMessage(error)}.`,
                    });
                  },
                }
              );
            }}
          >
            Confirm
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
}

// react final form use '.' for nested field
// we need to escape/unescape
function escapeKey(key: string) {
  return key.replaceAll('.', '_');
}

function unEscapeKey(key: string) {
  return key.replaceAll('_', '.');
}

const formControlOverrides: FormControlOverrides = {
  Label: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        ...theme.typography.Body2,
        marginTop: '-8px',
      };
    },
  },
};
