import { withStyle } from 'baseui';
import { StyledTableBodyCell as BaseBodyCell, StyledTableHeadCell as BaseTableCell } from 'baseui/table-semantic';

export const StyledTableBodyCell = withStyle(BaseBodyCell, () => {
  return {
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '8px',
    paddingBottom: '8px',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  };
});

export const StyledTableHeadCell = withStyle(BaseTableCell, () => ({
  paddingLeft: '8px',
  paddingRight: '8px',
  paddingTop: '8px',
  paddingBottom: '8px',
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 600,
}));
